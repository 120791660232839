<div>
  <h2>{{ 'Settings::NamedEntityRecognition' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form [formGroup]="form" (ngSubmit)="onSubmitForm()">
  <div class="form-group pt-2 selector-class">
    <label for="definitions">
      {{ 'Settings::NamedEntityRecognitionEnabledDefinitions' | abpLocalization }}
    </label>
    <ng-select
      formControlName="definitions"
      id="definitions"
      [items]="definitionList"
      bindLabel="labelClass"
      bindValue="id"
      [multiple]="true"
      [closeOnSelect]="false">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <input
          id="item-{{ index }}"
          type="checkbox"
          [ngModel]="item$.selected"
          [ngModelOptions]="{ standalone: true }" />
        <label class="ps-1" for="item-{{ index }}">
          {{ 'Conversation::NERDefinition:' + item.labelClass | abpLocalization }}
        </label>
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        <span class="ng-value-icon left" aria-hidden="true" (click)="removeFromSelected(item.id)">
          ×
        </span>
        <span class="ng-value-label">
          {{ 'Conversation::NERDefinition:' + item.labelClass | abpLocalization }}
        </span>
      </ng-template>
    </ng-select>
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
