import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import { PlayerTimelineCategoryMarkerGroupDto } from '../models/player-timeline-category-marker-group.dto';
import { CategoryMarkerDto } from '../models/category-marker.dto';

@Component({
  selector: 'ca-player-timeline-category-layer',
  templateUrl: './player-timeline-category-layer.component.html',
  styleUrls: ['./player-timeline-category-layer.component.scss'],
})
export class PlayerTimelineCategoryLayerComponent implements OnInit {
  @ViewChild('layerElement', { static: true, read: ElementRef })
  layerElement: ElementRef;

  @Input()
  styles: any = '';

  @Output()
  categoryMarkerClick: EventEmitter<{
    startMillisecond: number;
  }> = new EventEmitter();

  private _maxMarkerSpacePixel = 100;
  private _duration: number;
  _width: number;
  _categoryMarks: CategoryMarkerDto[];
  markers: PlayerTimelineCategoryMarkerGroupDto[] = [];

  get layerClass(): string {
    if (this.markers.filter(x => x.class == 'even').length > 0) return 'layer multiple';
    else return 'layer single';
  }

  constructor() {}

  initializeLayer(marks: CategoryMarkerDto[], duration: number) {
    this._categoryMarks = marks;
    this._width = this.layerElement.nativeElement.offsetWidth;
    this._duration = duration * 1000;
    if (this._categoryMarks) {
      this.createMarkers();
    }
  }
  ngOnInit(): void {}

  onCategoryMarkerClick(eventArgs: { markerInfo: CategoryMarkerDto }) {
    const startMillisecond = eventArgs.markerInfo.startMillisecond;
    this.categoryMarkerClick.emit({ startMillisecond: startMillisecond });
  }

  createMarkers() {
    this.markers = [];
    const interval = this.calculatePixelIntervals();
    let classDef = 'odd';

    for (let i = 0; i < this._duration; i = i + interval) {
      const foundedMarks = this._categoryMarks
        .filter(n => n.startMillisecond > i && n.startMillisecond <= i + interval)
        .sort((a, b) => a.startMillisecond - b.startMillisecond);
      if (foundedMarks.length > 0) {
        const firstMark = foundedMarks[0];
        const marker = new PlayerTimelineCategoryMarkerGroupDto();
        marker.color = firstMark.color;
        marker.startMillisecond = firstMark.startMillisecond;
        marker.endMillisecond = firstMark.endMillisecond;
        marker.isQuickSearchMarker = firstMark.isQuickSearchMarker;
        marker.isAIGeneratedMarker = firstMark.isAIGeneratedMarker;
        marker.isCommentMarker = firstMark.isCommentMarker;
        marker.text = firstMark.text;
        if (this._duration === i + interval) {
          marker.position = this.findPosition(i);
        } else {
          marker.position = this.findPosition(firstMark.startMillisecond);
        }

        marker.class = classDef;
        if (classDef === 'odd') {
          classDef = 'even';
        } else {
          classDef = 'odd';
        }
        foundedMarks.forEach(conversationMark => {
          const markerchild = new CategoryMarkerDto();
          markerchild.color = conversationMark.color;
          markerchild.text = conversationMark.text;
          markerchild.isQuickSearchMarker = conversationMark.isQuickSearchMarker;
          markerchild.isFilterSearchMarker = conversationMark.isFilterSearchMarker;
          markerchild.isAIGeneratedMarker = conversationMark.isAIGeneratedMarker;
          markerchild.isCommentMarker = conversationMark.isCommentMarker;
          markerchild.startMillisecond = conversationMark.startMillisecond;
          markerchild.endMillisecond = conversationMark.endMillisecond;
          marker.markers.push(markerchild);
        });

        this.markers.push(marker);
      } else {
        classDef = 'odd';
      }
    }
  }

  calculatePixelIntervals(): number {
    const segmentWidth = this._width / 10;
    return Math.round(segmentWidth / (this._width / this._duration));
  }

  findPosition(startMillisecond: number): number {
    let percentage = 0;
    percentage = (startMillisecond / this._duration) * 100;
    if (percentage > 90) {
      let markerDiffPercentage = (this._maxMarkerSpacePixel / this._width) * 100;
      if (percentage + markerDiffPercentage > 100) {
        percentage = 100 - markerDiffPercentage;
      }
    }
    return percentage;
  }

  onMarkerHover(eventArgs: { markerInfo: PlayerTimelineCategoryMarkerGroupDto }) {
    const markerInfo = eventArgs.markerInfo;
  }
}
