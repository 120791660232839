import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { GenAiType } from 'src/core/models/generic-lookup-type/analysis/gen-ai-type.glt';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';

@Component({
  selector: 'ca-open-ai-settings',
  templateUrl: './open-ai-settings.component.html',
  styleUrls: ['./open-ai-settings.component.scss'],
})
export class OpenAiSettingsComponent implements OnInit {
  openAiSettingsForm: FormGroup;
  openAiSettingDtos: ConfigurationSettingsDto[] = [];
  genAITypes: GenericLookupDto[];

  private readonly settingKeyGenAIType = 'GenAI.Type';
  private readonly settingKeyModel = 'OpenAI.Model';
  private readonly settingKeyTemperature = 'OpenAI.Temperature';
  private readonly settingKeyMaximumNumberOfTokens = 'OpenAI.MaximumNumberOfTokens';
  private readonly settingKeyTopP = 'OpenAI.TopP';
  private readonly settingKeyFrequencyPenalty = 'OpenAI.FrequencyPenalty';
  private readonly settingKeyPresencePenalty = 'OpenAI.PresencePenalty';
  private readonly settingKeyPromptPrefix = 'OpenAI.PromptPrefix';
  private readonly settingKeyApiUri = 'OpenAI.ApiUri';
  private readonly settingKeyApiKey = 'OpenAI.ApiKey';

  onSubmitOpenAiSettings() {
    if (this.openAiSettingsForm.invalid) {
      return;
    }
    this.openAiSettingDtos = new Array();

    this.openAiSettingDtos.push({
      settingName: this.settingKeyGenAIType,
      settingValue: this.openAiSettingsForm.get('type').value.toString(),
    });

    if (
      this.openAiSettingsForm.get('model').value &&
      this.openAiSettingsForm.get('model').enabled
    ) {
      this.openAiSettingDtos.push({
        settingName: this.settingKeyModel,
        settingValue: this.openAiSettingsForm.get('model').value.toString(),
      });
    }

    this.openAiSettingDtos.push({
      settingName: this.settingKeyTemperature,
      settingValue: this.openAiSettingsForm.get('temperature').value.toString(),
    });

    this.openAiSettingDtos.push({
      settingName: this.settingKeyMaximumNumberOfTokens,
      settingValue: this.openAiSettingsForm.get('maximumNumberOfTokens').value.toString(),
    });

    this.openAiSettingDtos.push({
      settingName: this.settingKeyTopP,
      settingValue: this.openAiSettingsForm.get('topP').value.toString(),
    });

    this.openAiSettingDtos.push({
      settingName: this.settingKeyFrequencyPenalty,
      settingValue: this.openAiSettingsForm.get('frequencyPenalty').value.toString(),
    });

    this.openAiSettingDtos.push({
      settingName: this.settingKeyPresencePenalty,
      settingValue: this.openAiSettingsForm.get('presencePenalty').value.toString(),
    });

    this.openAiSettingDtos.push({
      settingName: this.settingKeyPromptPrefix,
      settingValue: this.openAiSettingsForm.get('promptPrefix').value.toString(),
    });

    this.openAiSettingDtos.push({
      settingName: this.settingKeyApiUri,
      settingValue: this.openAiSettingsForm.get('apiUri').value.toString(),
    });

    if (this.openAiSettingsForm.get('apiKey').value) {
      this.openAiSettingDtos.push({
        settingName: this.settingKeyApiKey,
        settingValue: this.openAiSettingsForm.get('apiKey').value.toString(),
      });
    }

    this.settingService.saveSetting(this.openAiSettingDtos).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }

  onTypeChange() {
    if (
      this.openAiSettingsForm.get('type').value.toString().toLowerCase() ===
      this.genAITypes.find(x => x.id === GenAiType.azureOpenAI)?.code.toLowerCase()
    ) {
      this.openAiSettingsForm.get('model').disable();
    } else {
      this.openAiSettingsForm.get('model').enable();
    }
  }

  constructor(
    private config: ConfigStateService,
    private fb: FormBuilder,
    private store: Store,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(GenAiType)))
      .subscribe(result => {
        this.genAITypes = result;
      });

    this.openAiSettingsForm = fb.group({
      type: [this.config.getSetting(this.settingKeyGenAIType)],
      model: [this.config.getSetting(this.settingKeyModel)],
      temperature: [this.config.getSetting(this.settingKeyTemperature)],
      maximumNumberOfTokens: [this.config.getSetting(this.settingKeyMaximumNumberOfTokens)],
      topP: [this.config.getSetting(this.settingKeyTopP)],
      frequencyPenalty: [this.config.getSetting(this.settingKeyFrequencyPenalty)],
      presencePenalty: [this.config.getSetting(this.settingKeyPresencePenalty)],
      promptPrefix: [this.config.getSetting(this.settingKeyPromptPrefix)],
      apiUri: [this.config.getSetting(this.settingKeyApiUri)],
      apiKey: [this.config.getSetting(this.settingKeyApiKey)],
    });

    this.onTypeChange();
  }

  ngOnInit(): void {}
}
