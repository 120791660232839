import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TagDto } from 'src/core/models/conversation/tag/tag.dto';
import { NERDefinitionDto } from 'src/core/models/conversation/named-entity-recognition/named-entity-recognition-definition.dto';

@Injectable({
    providedIn: 'root',
})
export class NamedEntityRecognitionService {
    constructor(private http: HttpClient) { }

    updateEnability(definitionIds: number[]) {
        const url = this.getApiUrl() + '/update-enability';
        return this.http.put(url, definitionIds);
    }

    private getApiUrl(): string {
        const apiBase = environment.apis.default.url;

        return apiBase.length > 0 ? apiBase + '/' + NERDefinitionDto.apiUrl : NERDefinitionDto.apiUrl;
    }
}
