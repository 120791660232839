import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { take } from 'rxjs';
import { NERDefinitionDto } from 'src/core/models/conversation/named-entity-recognition/named-entity-recognition-definition.dto';
import { NamedEntityRecognitionService } from 'src/core/services/conversation/named-entity-recognition.service';
import { CrudService } from 'src/core/services/crud/crud.service';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';

@Component({
  selector: 'ca-named-entity-recognition-settings',
  templateUrl: './named-entity-recognition-settings.component.html',
  styleUrls: ['./named-entity-recognition-settings.component.scss']
})
export class NamedEntityRecognitionSettingsComponent implements OnInit {
  form: FormGroup;
  definitionList: NERDefinitionDto[] = [];

  constructor(
    private fb: FormBuilder,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private service: CrudService,
    private nerDefinitionService: NamedEntityRecognitionService,
    private globalSettingsService: GlobalSettingsService,
  ) {
    this.form = fb.group({
      definitions: [],
    });
    let langCodes = [];
    langCodes.push(this.globalSettingsService.systemLanguageCode);
    langCodes = [...langCodes, ...this.globalSettingsService.otherLangCodes.split(';').filter(x => x.length > 0)];

    let useTurkishLanguageCode = langCodes.filter(x => x == "tr-TR").length > 0;

    this.service
      .get<NERDefinitionDto>(NERDefinitionDto, {
        maxResultCount: 9999,
        skipCount: 0,
        filters: [],
        sorters: [],
      })
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.definitionList = useTurkishLanguageCode ? response.items : response.items.filter(x => x.labelClass !== "Tckn");
          this.form.get('definitions').setValue(this.definitionList.filter(x => x.isEnabled).map(x => x.id));
        },
        error: () => { },
      });
  }

  ngOnInit(): void { }

  onSubmitForm() {
    if (!this.form.valid) {
      return;
    }

    let definitions = this.form.get('definitions').value ?? [];
    this.nerDefinitionService.updateEnability(definitions).subscribe(response => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });;
  }

  removeFromSelected(id) {
    let selectedItems = this.form.get('definitions').value ?? [];
    if (selectedItems) {
      const indexToRemoveFromSelected = selectedItems.findIndex(idx => idx === id);
      if (indexToRemoveFromSelected > -1) {
        selectedItems.splice(indexToRemoveFromSelected, 1);
        this.form.get('definitions').setValue(selectedItems);
      }
    }
  }
}
