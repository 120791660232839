<div>
  <h2>{{ 'Settings::OpenAiSettings' | abpLocalization }}</h2>
</div>

<form [formGroup]="openAiSettingsForm" (ngSubmit)="onSubmitOpenAiSettings()">
  <div class="form-group pt-2">
    <div class="form-group">
      <label for="systemLanguageCode">
        {{ 'Settings::GenAI.Provider' | abpLocalization }}
      </label>
      <ca-select [class]="'below-all'" formControlName="type" (change)="onTypeChange()">
        <ca-option *ngFor="let genAIType of genAITypes" [value]="genAIType.code">
          {{ 'Settings::' + genAIType.code | abpLocalization }}
        </ca-option>
      </ca-select>
    </div>
  </div>
  <div class="form-group pt-2">
    <label for="apiUri">
      {{ 'Settings::OpenAIApiUri' | abpLocalization }}
    </label>
    <input type="text" class="form-control form-control-sm" id="apiUri" formControlName="apiUri" />
  </div>
  <div class="form-group pt-2">
    <label for="apiKey">
      {{ 'Settings::OpenAIApiKey' | abpLocalization }}
    </label>
    <input
      type="password"
      class="form-control form-control-sm"
      id="apiKey"
      formControlName="apiKey"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization" />
  </div>
  <div class="form-group pt-2" *ngIf="openAiSettingsForm.get('model').enabled">
    <label for="model">
      {{ 'Settings::OpenAIApiModel' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="model"
      formControlName="model"
      [placeholder]="'Settings::OpenAIApiModelPlaceholder' | abpLocalization" />
  </div>
  <div class="form-group pt-2">
    <h4>
      {{ 'Settings::OpenAIPromptTitle' | abpLocalization }}
    </h4>
    <label for="promptPrefix">
      {{ 'Settings::OpenAIConversationSummarization' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="promptPrefix"
      formControlName="promptPrefix" />
  </div>
  <div class="form-group pt-2">
    <label for="maximumNumberOfTokens">
      {{ 'Settings::OpenAIMaximumNumberOfTokens' | abpLocalization }}
    </label>
    <input
      type="number"
      class="form-control form-control-sm"
      id="maximumNumberOfTokens"
      formControlName="maximumNumberOfTokens" />
    <span>
      <small class="form-text text-muted">
        {{ 'Settings::OpenAIMaximumNumberOfTokensDescription' | abpLocalization }}
      </small>
      <a
        [href]="'Settings::OpenAIMaximumNumberOfTokensDescriptionLink' | abpLocalization"
        target="_blank">
        <small>
          {{ 'Settings::OpenAIMaximumNumberOfTokensDescriptionLink' | abpLocalization }}
        </small>
      </a>
    </span>
  </div>
  <div class="form-group pt-2">
    <label for="topP">
      {{ 'Settings::OpenAITopP' | abpLocalization }}
    </label>
    <input type="number" class="form-control form-control-sm" id="topP" formControlName="topP" />
  </div>
  <div class="form-group pt-2">
    <label for="frequencyPenalty">
      {{ 'Settings::OpenAIFrequencyPenalty' | abpLocalization }}
    </label>
    <input
      type="number"
      class="form-control form-control-sm"
      id="frequencyPenalty"
      formControlName="frequencyPenalty" />
  </div>
  <div class="form-group pt-2">
    <label for="presencePenalty">
      {{ 'Settings::OpenAIPresencePenalty' | abpLocalization }}
    </label>
    <input
      type="number"
      class="form-control form-control-sm"
      id="presencePenalty"
      formControlName="presencePenalty" />
  </div>
  <div class="form-group pt-2">
    <label for="temperature">
      {{ 'Settings::OpenAITemperature' | abpLocalization }}
    </label>
    <input
      type="number"
      class="form-control form-control-sm"
      id="temperature"
      formControlName="temperature" />
  </div>

  <div>
    <button
      type="button"
      type="submit"
      class="btn btn-primary mt-3"
      [disabled]="!openAiSettingsForm.valid">
      <i class="me-1 fas fa-save"></i>
      {{ 'AbpUi::Save' | abpLocalization }}
    </button>
  </div>
</form>
