import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Injector,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CrudService } from 'src/core/services/crud/crud.service';
import { UserDto } from 'src/core/models/shared/user.dto';
import { UserFormEditTypes } from 'src/core/models/administration/user/user-form-edit-type.enum';
import { UserPermissionSelectorComponent } from '../../user-permission-selector/user-permission-selector.component';
import { UserService } from 'src/core/services/administration/user.service';
import { UserEmailValidator } from 'src/core/validators/user/user-email.validator';
import { UsernameValidator } from 'src/core/validators/user/username.validator';
import { DepartmentService } from 'src/core/services/administration/department-service';
import { DropdownTreeNodeModel } from 'src/ca-shared/dropdown-tree/models/dropdown-tree-node.model';
import { DropdownTreeComponent } from 'src/ca-shared/dropdown-tree/components/dropdown-tree/dropdown-tree.component';
import { Operators } from 'src/core/models/request/operator.enum';
import { UserPhotoComponent } from 'src/ca-shared/user-photo/user-photo.module';
import { FormGroupStatus } from 'src/core/constants/form/form-group-status.constant';
import { Confirmation, ConfirmationService, getPasswordValidators } from '@abp/ng.theme.shared';
import { DropdownSelectorComponent } from 'src/ca-shared/selector/components/dropdown-selector/dropdown-selector.component';
import { LocalizationService, ConfigStateService } from '@abp/ng.core';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { RoleDto } from 'src/core/models/shared/role.dto';
import { UserInteractionListComponent } from '../../user-interaction-list/user-interaction-list.component';
import { combineLatest, Observable } from 'rxjs';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { UserPbxAgentIdValidator } from 'src/core/validators/user/user-pbxAgentId.validator';
import { UserDeviceIdValidator } from 'src/core/validators/user/user-deviceId.validator';
import { StringValidator } from 'src/core/validators/shared/string.validator';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { RoleConstants } from 'src/core/constants/role.constant';
import { CAConfigStateService } from 'src/core/services/config/ca-config-state.service';

@Component({
  selector: 'ca-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, AfterViewInit {
  @ViewChild('permissionSelector')
  permissionSelector: UserPermissionSelectorComponent;

  @ViewChild('interactionList')
  interactionList: UserInteractionListComponent;

  @ViewChild('roleSelector')
  roleSelectorComponent: DropdownSelectorComponent;

  @ViewChild('fileUpload')
  fileUpload: ElementRef;

  @ViewChild('departmentDropdownTree')
  departmentDropdownTree: DropdownTreeComponent;

  @ViewChild('userProfilePhoto')
  userProfilePhoto: UserPhotoComponent;

  @ViewChild('userProfilePhotoUpload')
  userProfilePhotoUpload: UserPhotoComponent;

  @ViewChild('userSelector')
  userSelector: DropdownSelectorComponent;

  @ViewChild('hiddenSubmitButton', { static: false })
  hiddenSubmitButton: ElementRef;

  @Output()
  saved: EventEmitter<{
    id: string;
  }> = new EventEmitter();

  @Output()
  saveUserRequested: EventEmitter<{
    user: UserDto;
    closeAfterSave: boolean;
  }> = new EventEmitter();

  @Output()
  deleteUserRequested: EventEmitter<{
    userId: string;
  }> = new EventEmitter();
  activeTab: 1;
  mainLayout = 'usersViewLayout';
  section = 'users';
  departmentDropDownTreeData: DropdownTreeNodeModel[] = [];
  userForm: FormGroup = null;
  user: UserDto = new UserDto();
  activeTabId: number = 1;
  editType: string;
  currentUserId: string;
  userFormEditTypes = UserFormEditTypes;
  photoUploadIsDefault = false;
  emailValidator: UserEmailValidator;
  usernameValidator: UsernameValidator;
  pbxAgentIdValidator: UserPbxAgentIdValidator;
  deviceIdValidator: UserDeviceIdValidator;
  showPassword = true;
  processing = false;
  roleDropdownOptions: any;
  userDropdownOptions: any;
  userFilters: FilterItemDto[] = [];
  userRoles: RoleDto[];
  permissionsDisabled: boolean;
  userGroupFeatureEnabled: boolean = false;
  closeAfterSave: boolean = false;
  requiredPhoneNumber: boolean = false;
  oldData: any;
  currenUserRoles: string[] = [];
  adminRoleRemoved: boolean = false;

  constructor(
    private fb: FormBuilder,
    private service: CrudService,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private departmentService: DepartmentService,
    private featureService: FeatureService,
    private injector: Injector,
    private operators: Operators,
    private localizationService: LocalizationService,
    private caConfigStateService: CAConfigStateService,
    private configStateService: ConfigStateService
  ) {
    this.setSupervisorSelectorOptions();

    this.userGroupFeatureEnabled = this.featureService.isEnabled(
      FeatureConstants.UserGroupManagement
    );

    this.usernameValidator = new UsernameValidator(this.userService);
    this.emailValidator = new UserEmailValidator(this.userService);
    this.pbxAgentIdValidator = new UserPbxAgentIdValidator(this.userService);
    this.deviceIdValidator = new UserDeviceIdValidator(this.userService);

    this.userForm = this.fb.group({
      id: [null],
      userName: [
        null,
        {
          validators: [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(256),
            this.usernameValidator.patternValidator,
          ],
          asyncValidators: [this.usernameValidator],
          updateOn: 'blur',
        },
      ],
      name: [
        '',
        {
          validators: [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(64),
            StringValidator.whitespace,
          ],
        },
      ],
      middleName: [
        null,
        {
          validators: [Validators.maxLength(64), StringValidator.whitespace],
        },
      ],
      surname: [
        null,
        {
          validators: [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(64),
            StringValidator.whitespace,
          ],
        },
      ],
      email: [
        null,
        {
          validators: [Validators.required, Validators.maxLength(256), Validators.email],
          asyncValidators: [this.emailValidator],
          updateOn: 'blur',
        },
      ],
      phoneNumber: [
        null,
        {
          asyncValidators: [this.deviceIdValidator],
          updateOn: 'blur',
        },
      ],
      secondPhoneNumber: [
        null,
        {
          asyncValidators: [this.deviceIdValidator],
          updateOn: 'blur',
        },
      ],
      pbxAgentId: [
        null,
        {
          asyncValidators: [this.pbxAgentIdValidator],
          updateOn: 'blur',
        },
      ],
      lockoutEnabled: [false],
      isActive: [true],
      password: [null, { updateOn: 'change' }],
      passwordRepeat: [null, { updateOn: 'change' }],
      department: [
        null,
        {
          validators: [Validators.required],
          updateOn: 'change',
        },
      ],
      role: [null, { validators: [Validators.required], updateOn: 'change' }],
      ldapUser: [false],
      isInteractive: [true],
      supervisor: [null, { updateOn: 'change' }],
    });

    this.userForm.get('name').valueChanges.subscribe(name => {
      this.user.name = name;
    });

    this.userForm.get('middleName').valueChanges.subscribe(middleName => {
      this.user.middleName = middleName;
    });

    this.userForm.get('surname').valueChanges.subscribe(surname => {
      this.user.surname = surname;
    });

    this.userForm.get('phoneNumber').valueChanges.subscribe(phoneNumber => {
      this.user.phoneNumber = phoneNumber;
    });

    this.userForm.get('secondPhoneNumber').valueChanges.subscribe(secondPhoneNumber => {
      this.user.extraProperties.SecondPhoneNumber = secondPhoneNumber;
    });

    this.userForm.get('pbxAgentId').valueChanges.subscribe(pbxAgentId => {
      this.user.extraProperties.PbxAgentId = pbxAgentId;
    });

    this.userForm.get('email').valueChanges.subscribe(email => {
      this.user.email = email;
    });

    this.userForm.get('userName').valueChanges.subscribe(userName => {
      this.user.userName = userName;
    });

    this.userForm.get('department').valueChanges.subscribe(department => {
      this.user.extraProperties.Department = department;
    });

    this.userForm.get('supervisor').valueChanges.subscribe(supervisor => {
      this.user.extraProperties.Supervisor = supervisor;
    });

    this.roleDropdownOptions = {
      pageSize: 5,
      filterProperty: 'filter',
      sorterProperty: 'sorting',
      sorters: 'Name',
      useSimpleFilter: true,
      filters: '',
      emptyText: this.localizationService.instant('::SelectRole'),
      multiple: true,
      showSelections: true,
      url: 'api/identity/roles',
    };
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.userForm.markAsDirty();
      this.userForm.updateValueAndValidity();
    }, 5000);
  }

  private setSupervisorSelectorOptions() {
    this.userFilters.push({
      field: 'isActive',
      operator: this.operators.Equals,
      value: true,
    });

    if (this.userSelector) {
      this.userSelector.filters = this.userFilters;
      this.userSelector.currentPage = 1;
    }

    this.userDropdownOptions = {
      pageSize: 5,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchFilter',
      emptyText: this.localizationService.instant('User::SelectSupervisor'),
      multiple: false,
      url: 'api/app/user',
      currentPage: 1,
      showSelections: true,
    };
  }

  ngOnInit() {
    this.currenUserRoles = this.configStateService.getDeep('currentUser.roles');
    this.loadDepartmentData();
  }

  loadDepartmentData() {
    if (this.departmentDropdownTree == null || this.departmentDropdownTree['_data'].length == 0) {
      this.departmentService
        .getTreeNodes<DropdownTreeNodeModel>({
          filters: [
            {
              field: 'includePassives',
              operator: this.operators.Equals,
              value: false,
            },
          ],
          sorters: [],
          maxResultCount: 99999,
          skipCount: 0,
        })
        .subscribe(data => {
          this.departmentDropDownTreeData = [];
          data.forEach(element => {
            this.departmentDropDownTreeData.push({
              expanded: element.expandable,
              leaf: !element.expandable,
              payload: element,
              disabled: false,
            });
          });
          this.departmentDropdownTree.data = this.departmentService.setRootNode(
            this.departmentDropDownTreeData
          );
        });
    }
  }
  onRoleSelectionChanged() {
    if (
      this.user.id != '' &&
      JSON.stringify(this.userRoles.sort()) !==
      JSON.stringify(this.userForm.get('role').value.sort())
    ) {
      if (!this.permissionsDisabled) {
        this.confirmationService
          .warn('User::UserRoleChangeMessage', 'User::UserRoleChangeTitle', {
            yesText: '::Change',
          })
          .subscribe((status: Confirmation.Status) => {
            if (status === Confirmation.Status.confirm) {
              this.permissionsDisabled = true;
            } else {
              this.userForm.controls['role'].setValue(ObjectHelper.deepCopy(this.userRoles));
            }
            this.phoneNumberValidator(this.userForm);
          });
      }
    } else {
      this.permissionsDisabled = false;
    }
    this.phoneNumberValidator(this.userForm);
  }
  loadRoleData(userId) {
    if (userId !== '') {
      const userRoleRequest = this.service.abpGetById<ListResponseDto<RoleDto>>(
        UserDto,
        userId,
        'roles'
      );
      combineLatest([userRoleRequest]).subscribe(([userRoles]) => {
        if (!this.currenUserRoles.includes(RoleConstants.admin)) {
          this.adminRoleRemoved =
            userRoles.items.filter(x => x.name == RoleConstants.admin).length > 0 ? true : false;
          userRoles.items = userRoles.items.filter(x => x.name !== RoleConstants.admin);
        }
        this.userRoles = ObjectHelper.deepCopy(userRoles.items);
        this.userForm.controls['role'].setValue(userRoles.items);
        this.phoneNumberValidator(this.userForm);
        this.oldData = JSON.stringify(this.userForm.getRawValue());
      });
    } else {
      const roleRequest = this.service.abpGet<RoleDto>(
        RoleDto,
        {
          filters: null,
          sorters: null,
          maxResultCount: 1000,
          skipCount: 0,
        },
        '',
        'Name'
      );
      let defaultRoles: RoleDto[] = [];
      combineLatest([roleRequest]).subscribe(([roles]) => {
        if (!this.currenUserRoles.includes(RoleConstants.admin)) {
          roles.items = roles.items.filter(x => x.name !== RoleConstants.admin);
        }

        roles.items.forEach(r => {
          if (r.isDefault) {
            defaultRoles.push(r);
          }
        });

        this.userForm.get('role').setValue(defaultRoles);
        this.phoneNumberValidator(this.userForm);
        this.oldData = JSON.stringify(this.userForm.getRawValue());
      });
    }
  }

  loadUser(user: UserDto) {
    this.setUserPermissionDefaults();
    this.setValidators(user);

    if (user.id === '') {
      this.showPassword = true;
      this.user = user;
      this.userForm.patchValue(this.user);
      if (this.user.extraProperties.LdapUser) {
        this.userForm.disable();
      }
    } else {
      this.userFilters = [];

      this.userFilters.push({
        field: 'isActive',
        operator: this.operators.Equals,
        value: true,
      });

      this.userFilters.push({
        field: 'id',
        operator: this.operators.NotEquals,
        value: user.id,
      });

      if (this.userSelector) {
        this.userSelector.filters = this.userFilters;
        this.userSelector.currentPage = 1;
        this.userSelector.refresh();
      }

      this.user = user;
      this.userForm.patchValue(this.user);

      this.userForm.controls['isActive'].setValue(this.user.extraProperties.IsActive);
      this.userForm.controls['middleName'].setValue(this.user.extraProperties.MiddleName);
      this.userForm.controls['ldapUser'].setValue(this.user.extraProperties.LdapUser);
      this.userForm.controls['isInteractive'].setValue(this.user.extraProperties.IsInteractive);
      this.userForm.controls['pbxAgentId'].setValue(this.user.extraProperties.PbxAgentId);
      this.userForm.controls['secondPhoneNumber'].setValue(
        this.user.extraProperties.SecondPhoneNumber
      );

      this.userForm.controls['department'].patchValue(this.user.extraProperties.Department);
      if (this.user.extraProperties.Supervisor) {
        this.userForm.controls['supervisor'].patchValue(this.user.extraProperties.Supervisor);
      }
      this.showPassword = false;

      if (this.user.extraProperties.LdapUser) {
        this.userForm.disable();
      }
      this.userForm.get('isActive').valueChanges.subscribe(isActive => {
        this.deviceIdValidator.isActive = isActive;
        this.pbxAgentIdValidator.isActive = isActive;
        if (isActive) {
          this.userForm.get('phoneNumber').updateValueAndValidity();
          this.userForm.get('pbxAgentId').updateValueAndValidity();
        }
      });
    }

    // if password field is visible then apply the validations, otherwise it remains invalid for all types of form as edit user or new user.
    if (this.showPassword) {
      this.userForm
        .get('password')
        .setValidators([
          Validators.required,
          ...getPasswordValidators(this.injector),
          this.checkPasswordsAreEqual.bind(this, ['password']),
        ]);

      this.userForm
        .get('passwordRepeat')
        .setValidators([
          Validators.required,
          this.checkPasswordsAreEqual.bind(this, ['passwordRepeat']),
        ]);
    }

    this.loadRoleData(user.id);
  }

  setUserPermissionDefaults() {
    this.permissionsDisabled = false;
    if (this.permissionSelector) {
      this.permissionSelector.userId = '';
      this.permissionSelector.permissionGroups = [];
    }
    if (this.interactionList) {
      this.interactionList.userId = '';
      this.interactionList.userInteractedUsers = [];
    }
  }

  setValidators(user: UserDto) {
    this.usernameValidator.userId = user.id;
    this.emailValidator.userId = user.id;
    this.pbxAgentIdValidator.userId = user.id;
    this.pbxAgentIdValidator.isActive = user.extraProperties.IsActive;
    this.deviceIdValidator.userId = user.id;
    this.deviceIdValidator.isActive = user.extraProperties.IsActive;
  }

  checkPhoneNumber() {
    const phoneNumber = this.userForm.get('phoneNumber');
    const hasError = phoneNumber.value == null || phoneNumber.value == '';

    if (hasError) {
      this.addPhoneNumberValidationError('phoneNumber', hasError);
      return { required: hasError };
    } else {
      this.removePhoneNumberValidationError('phoneNumber');
      return null;
    }
  }

  getSelectionName(selection) {
    return selection.surname && selection.surname.length > 0
      ? selection.middleName && selection.middleName.length > 0
        ? selection.name + ' ' + selection.middleName + ' ' + selection.surname
        : selection.name + ' ' + selection.surname
      : selection.middleName && selection.middleName.length > 0
        ? selection.name + ' ' + selection.middleName
        : selection.name;
  }

  removePhoneNumberValidationError(controlName) {
    let control = this.userForm.get(controlName);

    if (control.errors) {
      delete control.errors.required;

      const errorLength = Object.keys(control.errors).length;

      if (errorLength === 0) {
        control.setErrors(null);
      } else {
        control.setErrors(control.errors);
      }
    } else {
      control.setErrors(null);
    }
  }

  addPhoneNumberValidationError(controlName, hasError) {
    let control = this.userForm.get(controlName);
    if (control.errors) {
      control.errors.required = hasError;
      control.setErrors(control.errors);
    } else {
      control.setErrors({ required: hasError });
    }
  }

  phoneNumberValidator(form: FormGroup) {
    let selectedRoles = form.get('role').value;
    let phoneNumber = form.get('phoneNumber');
    if (selectedRoles && selectedRoles.some(x => x.name == RoleConstants.agent)) {
      phoneNumber.setValidators([Validators.required, this.checkPhoneNumber.bind(this)]);
      this.requiredPhoneNumber = true;
      this.checkPhoneNumber();
    } else {
      form.get('phoneNumber').clearValidators();
      this.requiredPhoneNumber = false;
      this.removePhoneNumberValidationError('phoneNumber');
    }
  }

  onSaveRequested(closeAfterSave): void {
    this.closeAfterSave = closeAfterSave;

    this.hiddenSubmitButton.nativeElement.click();
  }

  onSubmitForm() {
    this.activeTabId = 1;
    if (this.processing || this.userForm.invalid) {
      return;
    }

    this.processing = true;

    // if form is valid, then save object.
    if (this.userForm.disabled || this.userForm.valid) {
      this.saveUser();
    }
    // if async validation is in progress, wait it/them to finish and listen for status changes.
    else if (this.userForm.pending) {
      const subscription = this.userForm.statusChanges.subscribe(status => {
        if (status === FormGroupStatus.VALID) {
          subscription.unsubscribe();
          this.saveUser();
        } else if (status === FormGroupStatus.PENDING) {
          this.processing = true;
        } else {
          subscription.unsubscribe();
          this.processing = false;
        }
      });
    }
  }

  saveUser() {
    this.user.userName = this.userForm.get('userName').value;
    this.user.name = this.userForm.get('name').value;
    this.user.extraProperties.MiddleName = this.userForm.get('middleName').value || '';
    this.user.surname = this.userForm.get('surname').value;
    this.user.email = this.userForm.get('email').value;
    this.user.phoneNumber = this.userForm.get('phoneNumber').value;
    this.user.password = this.userForm.get('password').value;
    this.user.lockoutEnabled = this.userForm.get('lockoutEnabled').value || false;
    this.user.extraProperties.LdapUser = this.userForm.get('ldapUser').value || false;
    this.user.extraProperties.IsInteractive = this.userForm.get('isInteractive').value || false;
    this.user.extraProperties.IsActive = this.userForm.get('isActive').value || false;
    this.user.extraProperties.DepartmentId =
      this.userForm.get('department').value[0][this.departmentDropdownTree.idProperty];

    this.user.extraProperties.SupervisorId = null;
    if (
      this.userForm.get('supervisor').value != null &&
      this.userForm.get('supervisor').value.length > 0
    ) {
      this.user.extraProperties.SupervisorId =
        this.userForm.get('supervisor').value[0][this.userSelector.idProperty];
    }

    this.user.roleNames = [];
    this.userForm.get('role').value.forEach(element => {
      this.user.roleNames.push(element.name);
    });

    if (this.adminRoleRemoved) {
      this.user.roleNames.push(RoleConstants.admin);
    }

    this.user.extraProperties.PbxAgentId = this.userForm.get('pbxAgentId').value;
    this.user.extraProperties.SecondPhoneNumber = this.userForm.get('secondPhoneNumber').value;

    this.saveUserRequested.emit({ user: this.user, closeAfterSave: this.closeAfterSave });
  }
  savePermissions(userId: string): Observable<any> {
    if (this.permissionSelector) {
      return this.permissionSelector.save(userId);
    }
  }

  setPermissionConfigState() {
    this.caConfigStateService.refreshAppState().subscribe();
  }

  onIsActiveChange(value: boolean) {
    if (!value) {
      this.userForm.controls['lockoutEnabled'].disable();
      this.user.lockoutEnabled = false;
      this.userForm.controls['lockoutEnabled'].setValue(false);
    } else {
      this.userForm.controls['lockoutEnabled'].enable();
    }
    this.user.extraProperties.IsActive = value;
  }

  onIsLdapChange(value: boolean, user) {
    if (!value && user.id === '') {
      this.showPassword = true;
      this.userForm.get('password').enable();
      this.userForm.get('passwordRepeat').enable();
      this.userForm.get('password').patchValue('');
      this.userForm.get('passwordRepeat').patchValue('');
    } else {
      this.showPassword = false;
      this.userForm.get('password').disable();
      this.userForm.get('passwordRepeat').disable();
      this.userForm.get('password').patchValue('1q2w3E*');
      this.userForm.get('passwordRepeat').patchValue('1q2w3E*');
    }
  }

  checkPasswordsAreEqual(controlName: string) {
    const password = this.userForm.get('password').value;
    const passwordRepeat = this.userForm.get('passwordRepeat').value;

    let hasError = this.userForm && passwordRepeat !== password;

    const otherControlName = controlName == 'password' ? 'passwordRepeat' : 'password';

    if (hasError) {
      this.addPasswordsNotMatchedValidationError(otherControlName, hasError);
      return { passwordsNotMatched: hasError };
    } else {
      this.removePasswordsNotMatchedValidationError(otherControlName);
      return null;
    }
  }

  removePasswordsNotMatchedValidationError(controlName) {
    let control = this.userForm.get(controlName);

    if (control.errors) {
      delete control.errors.passwordsNotMatched;

      const errorLength = Object.keys(control.errors).length;

      if (errorLength === 0) {
        control.setErrors(null);
      } else {
        control.setErrors(control.errors);
      }
    } else {
      control.setErrors(null);
    }
  }

  addPasswordsNotMatchedValidationError(controlName, hasError) {
    let control = this.userForm.get(controlName);
    if (control.errors) {
      control.errors.passwordsNotMatched = hasError;
      control.setErrors(control.errors);
    } else {
      control.setErrors({ passwordsNotMatched: hasError });
    }
  }

  requestDelete() {
    this.deleteUserRequested.emit({ userId: this.user.id });
  }
  confirmDeletion() {
    this.confirmationService
      .warn('::DeletionConfirmationMessage', '', {
        messageLocalizationParams: [this.user.userName],
        yesText: '::Delete',
      })
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          this.requestDelete();
        }
      });
  }
  onTabChanged(event) {
    if (event.nextId === 'permissionsTab' && !this.permissionSelector.userId) {
      this.permissionSelector.userId = this.user.id;
    }
    if (event.nextId === 'interactionsTab' && !this.interactionList.userId) {
      this.interactionList.userId = this.user.id;
    }
  }

  hasUnsavedChanges(): boolean {
    var userValues = this.userForm.getRawValue();
    var newData = JSON.stringify(userValues);
    return this.oldData !== newData;
  }

  removeUnsavedChanges() {
    var userValues = this.userForm.getRawValue();
    this.oldData = JSON.stringify(userValues);
  }
}
