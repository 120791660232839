import { EventEmitter, Injectable, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import {
  ActiveTabChange,
  CommentAdded,
  CommentsLoaded,
  EvaluationPanelStatusChange,
  EvaluationParametersChange,
  SummaryChange,
} from 'src/core/actions/conversation/conversation-detail.actions';
import { ConversationCommentDto } from 'src/core/models/comment/comment.dto';
import {
  ConversationDetailStateModel,
  EvaluationParameters,
} from 'src/core/models/conversation/conversation-detail.state-model';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationDetailState } from 'src/core/states/conversation/conversation-detail.state';
import { ConversationService } from './conversation.service';
import { map } from 'rxjs/operators';
import { ConversationCommentInputDto } from 'src/core/models/comment/comment-input.dto';
import { ConversationDetailTabs } from 'src/core/models/conversation-detail-tabs';
import { ConversationDetailTabStatus } from 'src/core/models/conversation/conversation-detail-tab-status.enum';
import { EvaluationPanelStatus } from 'src/core/models/conversation/evaluation-panel-status.enum';
import { EvaluationType } from 'src/core/models/generic-lookup-type/quality/evaluation-type.glt';
import { ConversationTranscriptDto } from 'src/core/models/conversation/transcript/conversation-transcript.dto';
import { CrudService } from '../crud/crud.service';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { ConversationTranscriptItemChannel } from 'src/core/models/conversation/transcript/conversation-transcript-item-channel.enum';
import { ConversationSummaryDto } from 'src/core/models/conversation/conversation-summary.dto';
import { ConversationSummaryStatus } from 'src/core/models/generic-lookup-type/conversation/conversation-summary-status.glt';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';
import { CategoryMarkerDto } from 'src/ca-shared/player/models/category-marker.dto';

@Injectable({
  providedIn: 'root',
})
export class ConversationDetailService {
  @Output()
  evaluationComplete = new EventEmitter();

  @Output()
  goToMarkClick: EventEmitter<{
    code: string;
    channelId: number;
  }> = new EventEmitter();

  @Output()
  seek: EventEmitter<{
    time: number;
  }> = new EventEmitter();

  @Output()
  addRegion: EventEmitter<{
    startMillisecond: number;
    endMillisecond: number;
  }> = new EventEmitter();

  @Output()
  categoryChange: EventEmitter<{
    categories: ConversationCategoryDto[];
    categoryMarks: CategoryMarkerDto[];
  }> = new EventEmitter();

  constructor(
    private store: Store,
    private conversationService: ConversationService,
    private crudService: CrudService,
    private conversationTranscriptItemChannel: ConversationTranscriptItemChannel
  ) {}

  changeActiveTab(tabId: string): void {
    const action = new ActiveTabChange(tabId);

    this.store.dispatch(action);
  }

  closeEvaluationPanel(): void {
    const action = new EvaluationPanelStatusChange(EvaluationPanelStatus.closed);

    this.store.dispatch(action);
  }

  showEvaluationPanel(): void {
    const action = new EvaluationPanelStatusChange(EvaluationPanelStatus.open);

    this.store.dispatch(action);
  }

  startEvaluation(formId: number, formVersionId: number): void {
    const action = new EvaluationParametersChange(
      formId,
      formVersionId,
      null,
      null,
      EvaluationType.original,
      true
    );

    this.store.dispatch(action);

    this.showEvaluationPanel();
  }

  startReEvaluation(
    formId: number,
    formVersionId: number,
    evaluationResultId: number,
    evaluationMasterId: number
  ): void {
    const action = new EvaluationParametersChange(
      formId,
      formVersionId,
      evaluationResultId,
      evaluationMasterId,
      EvaluationType.reEvaluation,
      true
    );

    this.store.dispatch(action);

    this.showEvaluationPanel();
  }

  startCalibration(
    formId: number,
    formVersionId: number,
    evaluationResultId: number,
    evaluationMasterId: number
  ): void {
    const action = new EvaluationParametersChange(
      formId,
      formVersionId,
      evaluationResultId,
      evaluationMasterId,
      EvaluationType.calibration,
      true
    );

    this.store.dispatch(action);

    this.showEvaluationPanel();
  }

  resumeEvaluation(
    formId: number,
    formVersionId: number,
    evaluationResultId: number,
    evaluationMasterId: number
  ): void {
    const action = new EvaluationParametersChange(
      formId,
      formVersionId,
      evaluationResultId,
      evaluationMasterId,
      EvaluationType.resumeDraft,
      true
    );

    this.store.dispatch(action);

    this.showEvaluationPanel();
  }

  cancelEvaluation(): void {
    this.closeEvaluationPanel();
  }

  completeEvaluation(closePanel: boolean = false): void {
    if (closePanel) {
      this.closeEvaluationPanel();
    }

    this.evaluationComplete.emit();
  }

  showEvaluationResult(evaluationResultId: number, evaluationMasterId: number): void {
    const action = new EvaluationParametersChange(
      null,
      null,
      evaluationResultId,
      evaluationMasterId,
      EvaluationType.original,
      false
    );

    this.store.dispatch(action);

    this.showEvaluationPanel();
  }

  getCurrentConversationId(): number {
    const state = this.store.selectSnapshot<ConversationDetailStateModel>(ConversationDetailState);

    return state.conversationId;
  }

  getCurrentConversation(): ConversationDto {
    const state = this.store.selectSnapshot<ConversationDetailStateModel>(ConversationDetailState);

    return state.conversation;
  }

  getTranscript(): ConversationTranscriptDto {
    const state = this.store.selectSnapshot<ConversationDetailStateModel>(ConversationDetailState);

    return state.transcript;
  }

  getActiveTab(): string {
    const state = this.store.selectSnapshot<ConversationDetailStateModel>(ConversationDetailState);

    return state.activeTab;
  }

  loadComments(explicitConversationId: number | null = null): Observable<ConversationCommentDto[]> {
    const conversationId = explicitConversationId ?? this.getCurrentConversationId();

    return this.conversationService.getCommentsByConversationId(conversationId);
  }

  loadSummary(explicitConversationId: number | null = null): Observable<ConversationSummaryDto> {
    const conversationId = explicitConversationId ?? this.getCurrentConversationId();

    return this.conversationService.getSummary(conversationId);
  }

  generateSummary(
    explicitConversationId: number | null = null
  ): Observable<ConversationSummaryDto> {
    const conversationId = explicitConversationId ?? this.getCurrentConversationId();

    const summary = new ConversationSummaryDto();

    summary.error = '';
    summary.topic = '';
    summary.summary = '';
    summary.statusId = ConversationSummaryStatus.generating;

    const action = new SummaryChange(summary);

    this.store.dispatch(action);

    return this.conversationService.generateSummary(conversationId).pipe(
      map(data => {
        const newAction = new SummaryChange(data);

        this.store.dispatch(newAction);

        return data;
      })
    );
  }

  loadTranscript(
    explicitConversation: ConversationDto | null = null
  ): Observable<ConversationTranscriptDto> {
    const conversation = explicitConversation ?? this.getCurrentConversation();

    if (conversation.typeId === ConversationType.email) {
      let transcript: ConversationTranscriptDto = {
        conversationId: conversation.id,
        conversationTypeId: conversation.typeId,
        items: [
          {
            channel: this.conversationTranscriptItemChannel.Agent,
            startTime: null,
            endTime: null,
            time: conversation.startTime,
            term: conversation?.email?.message,
            expansionProperties: {},
            index: null,
            agentIndex: null,
            customerIndex: null,
            participantName: null,
            rowIdentifier: null,
          },
        ],
      };

      const val = of(transcript);
      return val;
    }
    const transcriptObservable = this.crudService.getById<ConversationTranscriptDto>(
      ConversationDto,
      conversation.id,
      'transcript'
    );

    return transcriptObservable;
  }

  getComments(): ConversationCommentDto[] {
    const state = this.store.selectSnapshot<ConversationDetailStateModel>(ConversationDetailState);

    return state.comments;
  }

  getEvaluationParameters(): EvaluationParameters {
    const state = this.store.selectSnapshot<ConversationDetailStateModel>(ConversationDetailState);

    return state.evaluationParameters;
  }

  getSkipDeactivationCheck(): boolean {
    const state = this.store.selectSnapshot<ConversationDetailStateModel>(ConversationDetailState);

    return state.skipDeactivationCheck;
  }

  editTranscript(transcript: ConversationTranscriptDto): Observable<any[]> {
    return this.conversationService.editTranscript(transcript).pipe(
      map(data => {
        return data;
      })
    );
  }

  sendComment(comment: ConversationCommentInputDto): Observable<ConversationCommentDto> {
    return this.conversationService.addComment(comment).pipe(
      map(data => {
        const state =
          this.store.selectSnapshot<ConversationDetailStateModel>(ConversationDetailState);
        const commentsTabStatus = state.tabStatus.get(ConversationDetailTabs.comment);

        const commentAddedAction = new CommentAdded();
        this.store.dispatch(commentAddedAction);

        if (commentsTabStatus === ConversationDetailTabStatus.loaded) {
          const comments = state.comments;

          comments.unshift(data);

          const commentsLoadedAction = new CommentsLoaded(comments);
          this.store.dispatch(commentsLoadedAction);
        }

        return data;
      })
    );
  }

  goToMark(eventArgs: { code: string; channelId: number }): void {
    this.goToMarkClick.emit(eventArgs);
  }

  changePlayerPosition(eventArgs: { time: number }): void {
    this.seek.emit(eventArgs);
  }

  showRegion(eventArgs: { startMillisecond: number; endMillisecond: number }): void {
    this.addRegion.emit(eventArgs);
  }

  updateCategory(eventArgs: {
    categories: ConversationCategoryDto[];
    categoryMarks: CategoryMarkerDto[];
  }): void {
    this.categoryChange.emit(eventArgs);
  }
}
