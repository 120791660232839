import { ConversationCommentDto } from '../comment/comment.dto';
import { ConversationDetailTabs } from '../conversation-detail-tabs';
import { ConversationSummaryStatus } from '../generic-lookup-type/conversation/conversation-summary-status.glt';
import { EvaluationType } from '../generic-lookup-type/quality/evaluation-type.glt';
import { ConversationDetailTabStatus } from './conversation-detail-tab-status.enum';
import { ConversationExcelDetailDto } from './conversation-excel-detail.dto';
import { ConversationDto } from './conversation.dto';
import { EvaluationPanelStatus } from './evaluation-panel-status.enum';
import { ConversationTranscriptDto } from './transcript/conversation-transcript.dto';

export class ConversationDetailStateModelDefaults {
  static value: ConversationDetailStateModel = {
    conversationId: null,
    conversation: null,
    transcript: null,
    comments: [],
    activeTab: ConversationDetailTabs.transcript,
    skipDeactivationCheck: false,
    evaluationPanelStatus: EvaluationPanelStatus.closed,
    evaluationParameters: {
      formId: null,
      formVersionId: null,
      evaluationResultId: null,
      evaluationMasterId: null,
      evaluationType: EvaluationType.original,
      isEvaluating: false,
    },
    numberOfComments: 0,
    summary: {
      topic: '',
      summary: '',
      error: '',
      statusId: ConversationSummaryStatus.generating,
    },
    tabStatus: new Map<string, ConversationDetailTabStatus>([
      [ConversationDetailTabs.analysis, ConversationDetailTabStatus.initial],
      [ConversationDetailTabs.attachedData, ConversationDetailTabStatus.initial],
      [ConversationDetailTabs.comment, ConversationDetailTabStatus.initial],
      [ConversationDetailTabs.evaluation, ConversationDetailTabStatus.initial],
      [ConversationDetailTabs.history, ConversationDetailTabStatus.initial],
      [ConversationDetailTabs.transcript, ConversationDetailTabStatus.initial],
      [ConversationDetailTabs.transfer, ConversationDetailTabStatus.initial],
    ]),
    excelDetail: new ConversationExcelDetailDto(),
  };
}

export interface ConversationDetailStateModel {
  conversationId: number | null;
  conversation: ConversationDto | null;
  transcript: ConversationTranscriptDto;
  comments: ConversationCommentDto[];
  activeTab: string;
  tabStatus: Map<string, ConversationDetailTabStatus>;
  evaluationPanelStatus: EvaluationPanelStatus;
  numberOfComments: number;
  evaluationParameters: EvaluationParameters;
  skipDeactivationCheck: boolean;
  summary: {
    topic: string;
    summary: string;
    error: string;
    statusId: number;
  };
  excelDetail: ConversationExcelDetailDto;
}

export interface EvaluationParameters {
  formId: number | null;
  formVersionId: number | null;
  evaluationResultId: number | null;
  evaluationMasterId: number | null;
  evaluationType: number;
  isEvaluating: boolean;
}
