import { Injectable } from '@angular/core';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';
import { OrderedSimpleTermQueryItemDto } from 'src/core/models/query/ordered-simple-term-query-item.dto';
import { NearQueryItemDto } from 'src/core/models/query/near-query-item.dto';
import { SimpleTermQueryItemDto } from 'src/core/models/query/simple-term-query-item.dto';
import { SimpleTermWithOccurenceQueryItemDto } from 'src/core/models/query/simple-term-with-occurence-query-item.dto';
import { OrderedGroupQueryItemDto } from 'src/core/models/query/ordered-group-query-item.dto';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { Operators } from 'src/core/models/request/operator.enum';
import { ChatOccurrenceDto } from 'src/core/models/mark/chat-occurrence.dto';
import { SearchTermItemDto } from 'src/core/models/query/search-term-item.dto';
import { ChatTranscriptDto } from 'src/core/models/chat/chat-transcript.dto';
import { StringService } from '../helper/string.service';
import { ChatTranscriptChannel } from 'src/core/models/chat/chat-transcript-channel.enum';
import { AIGeneratedTermItemDto } from 'src/core/models/query/ai-generated-term-item.dto';
import { TranscriptWord } from 'src/core/models/conversation/transcript/transcript-word.model';
import { CategoryMarkerDto } from 'src/ca-shared/player/models/category-marker.dto';
@Injectable({
  providedIn: 'root',
})
export class ChatTranscriptService {
  private _groupedTranscript: ChatTranscriptDto[][] = [];
  private _chatTranscripts: ChatTranscriptDto[];
  constructor(
    private operators: Operators,
    private stringService: StringService,
    private chatTranscriptChannel: ChatTranscriptChannel
  ) {}

  getTranscriptAnalysis(
    categories: ConversationCategoryDto[],
    chatTranscripts: ChatTranscriptDto[],
    extraFilters?: any
  ): CategoryMarkerDto[] {
    let filteredCategories = categories.filter(x => x.rootQueryId == null);

    if (extraFilters) {
      filteredCategories = filteredCategories.filter(extraFilters);
    }
    this.applyCombinedQueries(filteredCategories, categories);

    let chatMarkResults: CategoryMarkerDto[] = [];
    this._chatTranscripts = this.splitLines(chatTranscripts);
    this.setChatTranscriptIndexes(this._chatTranscripts);
    this._groupedTranscript[1] = this._chatTranscripts.filter(
      x => x.channel == this.chatTranscriptChannel.Agent
    );
    this._groupedTranscript[2] = this._chatTranscripts.filter(
      x => x.channel == this.chatTranscriptChannel.Customer
    );
    filteredCategories.forEach(category => {
      if (category.searchTermItem != null) {
        chatMarkResults = chatMarkResults.concat(
          this.checkSearchTerm(category.searchTermItem, category)
        );
      }

      category.topicTermItems?.forEach(topic => {
        chatMarkResults = chatMarkResults.concat(this.checkTopicTerm(topic, category));
      });

      category.simpleTermItems?.forEach(queryItem => {
        chatMarkResults = chatMarkResults.concat(this.checkSimpleTermQuery(queryItem, category));
      });

      category.simpleTermWithOccurenceItems?.forEach(queryItem => {
        chatMarkResults = chatMarkResults.concat(
          this.checkSimpleTermWithOccurrenceQuery(queryItem, category)
        );
      });

      category.nearItems?.forEach(queryItem => {
        chatMarkResults = chatMarkResults.concat(this.checkNearQuery(queryItem, category));
      });

      category.orderedGroupItems?.forEach(queryItem => {
        chatMarkResults = chatMarkResults.concat(this.checkOrderedGroupQuery(queryItem, category));
      });
    });

    return chatMarkResults;
  }

  private splitLines(transcripts: ChatTranscriptDto[]) {
    let splitedTranscripts: ChatTranscriptDto[] = [];
    transcripts.forEach(transcript => {
      let words = transcript.line.split(' ');
      words.forEach(word => {
        splitedTranscripts.push({
          channel: transcript.channel,
          timeStamp: transcript.timeStamp,
          line: word.replace(/([?.,:;])/g, ''),
        });
      });
    });
    return splitedTranscripts;
  }

  private checkWordEquality(transcriptWord: string, termWord: string, operator: number) {
    if (
      operator === this.operators.Equals &&
      this.stringService.toLowerForCurrentCulture(transcriptWord) === termWord
    ) {
      return true;
    } else if (
      operator === this.operators.Contains &&
      this.stringService.toLowerForCurrentCulture(transcriptWord).indexOf(termWord) >= 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  private getOccurrences(
    term: string,
    operator: number,
    index: number = 0,
    sideId: number
  ): ChatOccurrenceDto[] {
    let occurrences: ChatOccurrenceDto[] = [];
    let termArray = term.trim().split(' ');
    let channel = this.chatTranscriptChannel.Agent;
    this._groupedTranscript.forEach(transcript => {
      for (let i = 0; i < transcript.length; i++) {
        if (termArray.length > 0 && transcript[i].index >= index) {
          let words: TranscriptWord[] = [];
          for (let k = 0; k < termArray.length; k++) {
            if (
              transcript[i + k] != null &&
              this.checkWordEquality(transcript[i + k].line, termArray[k], operator)
            ) {
              words.push({
                word: transcript[i + k].line,
              });
              if (k === termArray.length - 1) {
                if (this.checkSide(sideId, transcript[i].channel)) {
                  occurrences.push({
                    timeStamp: transcript[i].timeStamp,
                    channel: channel,
                    index: transcript[i].index,
                    words: words,
                  });
                }
              }
            } else {
              break;
            }
          }
        }
      }
      channel++;
    });

    return occurrences;
  }
  private checkNearQuery(query: NearQueryItemDto, category: ConversationCategoryDto) {
    let marks: CategoryMarkerDto[] = [];

    let firstTermOccurrences = this.getOccurrences(
      query.firstTerm,
      query.firstOperator,
      0,
      query.sideId
    );

    let secondTermOccurences = this.getOccurrences(
      query.secondTerm,
      query.secondOperator,
      0,
      query.sideId
    );
    if (firstTermOccurrences.length > 0 && secondTermOccurences.length > 0) {
      firstTermOccurrences.forEach(occurrence => {
        let secondTermOccurrencesInRange = secondTermOccurences.filter(
          x => x.index > occurrence.index && x.index <= occurrence.index + query.maximumDistance + 1
        );
        if (secondTermOccurrencesInRange.length > 0) {
          let mark = new CategoryMarkerDto();
          mark.color = category.categoryColor;
          mark.text = occurrence.words
            .map(item => {
              return item.word;
            })
            .join(' ');
          mark.channel = occurrence.channel;
          mark.timeStamp = occurrence.timeStamp;
          mark.categoryName = category.categoryName;
          mark.words = occurrence.words;
          mark.isQuickSearchMarker = category.isQuickSearchMarker;
          mark.isFilterSearchMarker = category.isFilterSearchMarker;
          mark.isAIGeneratedMarker = category.isAIGeneratedMarker;
          mark.isCommentMarker = category.isCommentMarker;
          marks.push(mark);
          secondTermOccurrencesInRange.forEach(secondTermOccurrence => {
            {
              let mark = new CategoryMarkerDto();
              mark.color = category.categoryColor;
              mark.text = secondTermOccurrence.words
                .map(item => {
                  return item.word;
                })
                .join(' ');
              mark.channel = secondTermOccurrence.channel;
              mark.timeStamp = secondTermOccurrence.timeStamp;
              mark.categoryName = category.categoryName;
              mark.words = secondTermOccurrence.words;
              mark.isQuickSearchMarker = category.isQuickSearchMarker;
              mark.isFilterSearchMarker = category.isFilterSearchMarker;
              mark.isAIGeneratedMarker = category.isAIGeneratedMarker;
              mark.isCommentMarker = category.isCommentMarker;
              marks.push(mark);
            }
          });
        }
      });

      if (!query.searchInOrder) {
        secondTermOccurences.forEach(occurrence => {
          let firstTermOcurrencesInRange = firstTermOccurrences.filter(
            x =>
              x.index > occurrence.index && x.index <= occurrence.index + query.maximumDistance + 1
          );
          if (firstTermOcurrencesInRange.length > 0) {
            let mark = new CategoryMarkerDto();
            mark.color = category.categoryColor;
            mark.text = occurrence.words
              .map(item => {
                return item.word;
              })
              .join(' ');
            mark.channel = occurrence.channel;
            mark.timeStamp = occurrence.timeStamp;
            mark.categoryName = category.categoryName;
            mark.words = occurrence.words;
            mark.isQuickSearchMarker = category.isQuickSearchMarker;
            mark.isFilterSearchMarker = category.isFilterSearchMarker;
            mark.isAIGeneratedMarker = category.isAIGeneratedMarker;
            mark.isCommentMarker = category.isCommentMarker;
            marks.push(mark);
            firstTermOcurrencesInRange.forEach(firstTermOccurrence => {
              {
                let mark = new CategoryMarkerDto();
                mark.color = category.categoryColor;
                mark.text = firstTermOccurrence.words
                  .map(item => {
                    return item.word;
                  })
                  .join(' ');
                mark.channel = firstTermOccurrence.channel;
                mark.timeStamp = firstTermOccurrence.timeStamp;
                mark.categoryName = category.categoryName;
                mark.words = firstTermOccurrence.words;
                mark.isQuickSearchMarker = category.isQuickSearchMarker;
                mark.isFilterSearchMarker = category.isFilterSearchMarker;
                mark.isAIGeneratedMarker = category.isAIGeneratedMarker;
                mark.isCommentMarker = category.isCommentMarker;
                marks.push(mark);
              }
            });
          }
        });
      }
    }

    return marks;
  }

  private checkOrderedGroupQuery(
    query: OrderedGroupQueryItemDto,
    category: ConversationCategoryDto
  ) {
    let marks = this.getOrderedSimpleCategoryMarkers(query.internalId, category);
    return marks;
  }

  private checkSimpleTermQuery(
    query: SimpleTermQueryItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];

    let occurrences = this.getOccurrences(query.term, query.operator, 0, query.sideId);
    occurrences.forEach(occurrence => {
      if (occurrences.length > 0) {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = occurrence.channel;
        mark.timeStamp = occurrence.timeStamp;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        mark.isAIGeneratedMarker = category.isAIGeneratedMarker;
        mark.isCommentMarker = category.isCommentMarker;
        marks.push(mark);
      }
    });

    return marks;
  }

  private checkSearchTerm(
    searchTerm: SearchTermItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];
    let operator = this.operators.Equals;

    if (searchTerm.term.indexOf('*') > -1) {
      operator = this.operators.Contains;
    }

    let occurrences = this.getOccurrences(
      searchTerm.term.replace('*', ''),
      operator,
      0,
      ConversationSide.any
    );
    occurrences.forEach(occurrence => {
      if (occurrences.length > 0) {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = occurrence.channel;
        mark.timeStamp = occurrence.timeStamp;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        mark.isAIGeneratedMarker = category.isAIGeneratedMarker;
        mark.isCommentMarker = category.isCommentMarker;
        marks.push(mark);
      }
    });
    return marks;
  }

  private checkSimpleTermWithOccurrenceQuery(
    query: SimpleTermWithOccurenceQueryItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];
    let occurrences = this.getOccurrences(query.term, query.operator, 0, query.sideId);
    if (query.sideId === ConversationSide.any || query.sideId === ConversationSide.customer) {
      let agentoccurrences = occurrences.filter(
        x => x.channel === this.chatTranscriptChannel.Agent
      );
      if (agentoccurrences.length >= query.occurence) {
        agentoccurrences.forEach(occurrence => {
          let mark = new CategoryMarkerDto();
          mark.color = category.categoryColor;
          mark.text = occurrence.words
            .map(item => {
              return item.word;
            })
            .join(' ');
          mark.channel = this.chatTranscriptChannel.Agent;
          mark.timeStamp = occurrence.timeStamp;
          mark.categoryName = category.categoryName;
          mark.words = occurrence.words;
          mark.isQuickSearchMarker = category.isQuickSearchMarker;
          mark.isFilterSearchMarker = category.isFilterSearchMarker;
          mark.isAIGeneratedMarker = category.isAIGeneratedMarker;
          mark.isCommentMarker = category.isCommentMarker;
          marks.push(mark);
        });
      }
      if (query.sideId === ConversationSide.any || query.sideId === ConversationSide.agent) {
        let agentoccurrences = occurrences.filter(
          x => x.channel === this.chatTranscriptChannel.Customer
        );
        if (agentoccurrences.length >= query.occurence) {
          agentoccurrences.forEach(occurrence => {
            let mark = new CategoryMarkerDto();
            mark.color = category.categoryColor;
            mark.text = occurrence.words
              .map(item => {
                return item.word;
              })
              .join(' ');
            mark.channel = this.chatTranscriptChannel.Customer;
            mark.timeStamp = occurrence.timeStamp;
            mark.categoryName = category.categoryName;
            mark.words = occurrence.words;
            mark.isQuickSearchMarker = category.isQuickSearchMarker;
            mark.isFilterSearchMarker = category.isFilterSearchMarker;
            mark.isAIGeneratedMarker = category.isAIGeneratedMarker;
            mark.isCommentMarker = category.isCommentMarker;
            marks.push(mark);
          });
        }
      }

      return marks;
    }
  }

  private checkSide(sideId: number, channelId: number): boolean {
    if (
      ((sideId === ConversationSide.any || sideId === ConversationSide.customer) &&
        channelId == this.chatTranscriptChannel.Customer) ||
      ((sideId === ConversationSide.any || sideId === ConversationSide.agent) &&
        channelId == this.chatTranscriptChannel.Agent)
    ) {
      return true;
    } else {
      return false;
    }
  }

  private getOrderedSimpleCategoryMarkers(
    internalId: number,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];
    let items: OrderedSimpleTermQueryItemDto[] = category.orderedSimpleTermItems
      .filter(x => x.parentInternalId === internalId)
      .sort((a, b) => a.internalId);

    if (items.length > 0) {
      // get first terms occurrences
      let firstItemOcurrences = this.getOccurrences(
        items[0].term,
        items[0].operator,
        0,
        items[0].sideId
      );

      firstItemOcurrences.forEach(occurrence => {
        let tempMarks: CategoryMarkerDto[] = [];

        if (
          !this.checkQueryMarkerExist(
            marks,
            items[0].term,
            occurrence.channel,
            occurrence.timeStamp
          )
        ) {
          let mark = new CategoryMarkerDto();
          mark.color = category.categoryColor;
          mark.text = occurrence.words
            .map(item => {
              return item.word;
            })
            .join(' ');
          mark.channel = occurrence.channel;
          mark.timeStamp = occurrence.timeStamp;
          mark.categoryName = category.categoryName;
          mark.words = occurrence.words;
          mark.isQuickSearchMarker = category.isQuickSearchMarker;
          mark.isFilterSearchMarker = category.isFilterSearchMarker;
          mark.isAIGeneratedMarker = category.isAIGeneratedMarker;
          mark.isCommentMarker = category.isCommentMarker;
          tempMarks.push(mark);
        }

        let lastIndex = occurrence.index + items[0].term.trim().split(' ').length;

        // check next terms occurrences
        for (let i = 1; i < items.length; i++) {
          let nextTermOccurence = this.getOccurrences(
            items[i].term,
            items[i].operator,
            lastIndex,
            items[i].sideId
          );

          if (nextTermOccurence.length > 0) {
            if (
              !this.checkQueryMarkerExist(
                marks,
                items[i].term,
                nextTermOccurence[0].channel,
                nextTermOccurence[0].timeStamp
              )
            ) {
              let mark = new CategoryMarkerDto();
              mark.color = category.categoryColor;
              mark.text = occurrence.words
                .map(item => {
                  return item.word;
                })
                .join(' ');
              mark.channel = nextTermOccurence[0].channel;
              mark.categoryName = category.categoryName;
              mark.words = nextTermOccurence[0].words;
              mark.timeStamp = nextTermOccurence[0].timeStamp;
              mark.isQuickSearchMarker = category.isQuickSearchMarker;
              mark.isFilterSearchMarker = category.isFilterSearchMarker;
              mark.isAIGeneratedMarker = category.isAIGeneratedMarker;
              mark.isCommentMarker = category.isCommentMarker;
              tempMarks.push(mark);
            }
            if (i + 1 == items.length) {
              marks = marks.concat(tempMarks);
            }
            lastIndex = nextTermOccurence[0].index + items[i].term.trim().split(' ').length;
          } else {
            break;
          }
        }
      });
    }
    return marks;
  }

  private setChatTranscriptIndexes(chatTranscripts: ChatTranscriptDto[]) {
    for (let i = 0; i < chatTranscripts.length; i++) {
      chatTranscripts[i].index = i;
    }
  }

  private checkQueryMarkerExist(
    marks: CategoryMarkerDto[],
    term: string,
    channel: number,
    timeStamp
  ) {
    let count = marks.filter(
      x => x.text === term && x.channel === channel && x.timeStamp === timeStamp
    ).length;
    if (count === 0) {
      return false;
    } else {
      return true;
    }
  }

  private checkTopicTerm(
    topic: AIGeneratedTermItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];

    let occurrences = this.getOccurrences(
      topic.term,
      this.operators.Equals,
      0,
      ConversationSide.any
    );
    occurrences.forEach(occurrence => {
      if (occurrences.length > 0) {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = occurrence.channel;
        mark.timeStamp = occurrence.timeStamp;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        mark.isAIGeneratedMarker = category.isAIGeneratedMarker;
        mark.isCommentMarker = category.isCommentMarker;
        marks.push(mark);
      }
    });

    return marks;
  }

  private applyCombinedQueries(filteredCategories: any, categories) {
    filteredCategories.forEach(filteredCategory => {
      var combinedQueries = categories.filter(x => x.rootQueryId == filteredCategory.id);
      combinedQueries.forEach(combinedQuery => {
        combinedQuery.categoryName = filteredCategory.categoryName;
        combinedQuery.categoryColor = filteredCategory.categoryColor;
        filteredCategories.push(combinedQuery);
      });
    });
  }
}
