import { Injectable } from '@angular/core';
import { CallTranscriptDto } from 'src/core/models/call/call-transcript.dto';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';
import { OrderedSimpleTermQueryItemDto } from 'src/core/models/query/ordered-simple-term-query-item.dto';
import { NearQueryItemDto } from 'src/core/models/query/near-query-item.dto';
import { OrderedGroupWithRangeQueryItemDto } from 'src/core/models/query/ordered-group-with-range-query-item.dto';
import { SimpleTermQueryItemDto } from 'src/core/models/query/simple-term-query-item.dto';
import { SimpleTermWithOccurenceQueryItemDto } from 'src/core/models/query/simple-term-with-occurence-query-item.dto';
import { SimpleTermWithRangeAndOccurenceQueryItemDto } from 'src/core/models/query/simple-term-with-range-and-occurence-query-item.dto';
import { SimpleTermWithRangeQueryItemDto } from 'src/core/models/query/simple-term-with-range-query-item.dto';
import { OrderedGroupQueryItemDto } from 'src/core/models/query/ordered-group-query-item.dto';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { Operators } from 'src/core/models/request/operator.enum';
import { CallOccurrenceDto } from 'src/core/models/mark/call-occurrence.dto';
import { QueryRangeUnit } from '../../models/generic-lookup-type/query/query-range-unit.glt';
import { SearchTermItemDto } from 'src/core/models/query/search-term-item.dto';
import { TranscriptWord } from 'src/core/models/conversation/transcript/transcript-word.model';
import { CallTranscriptChannel } from 'src/core/models/call/call-transcript-channel.enum';
import { StringService } from '../helper/string.service';
import { AIGeneratedTermItemDto } from 'src/core/models/query/ai-generated-term-item.dto';
import { FormatHelper } from 'src/core/helpers/format.helper';
import { CommentTermItemDto } from 'src/core/models/query/comment-term-item.dto';
import { TranscriptRow } from 'src/core/models/conversation/transcript/transcript-row.model';
import { CATimePipe } from 'src/core/pipes/ca-time.pipe';
import { ConfigStateService } from '@abp/ng.core';
import { ConversationTranscriptItemDto } from 'src/core/models/conversation/transcript/conversation-transcript-item.dto';
import { ConversationTranscriptDto } from 'src/core/models/conversation/transcript/conversation-transcript.dto';
import { ConversationTranscriptItemChannel } from 'src/core/models/conversation/transcript/conversation-transcript-item-channel.enum';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { CategoryMarkerDto } from 'src/ca-shared/player/models/category-marker.dto';
@Injectable({
  providedIn: 'root',
})
export class CallTranscriptService {
  private _groupedTranscript: CallTranscriptDto[][] = [];
  private _callTranscripts: CallTranscriptDto[];
  private _transcript: ConversationTranscriptDto;
  private _groupedNewTranscript: ConversationTranscriptItemDto[][] = [];
  constructor(
    private operators: Operators,
    private callTranscriptChannel: CallTranscriptChannel,
    private conversationTranscriptItemChannel: ConversationTranscriptItemChannel,
    private stringService: StringService,
    private formatHelper: FormatHelper,
    private caTimePipe: CATimePipe,
    private caDatePipe: CADatePipe,
    private config: ConfigStateService
  ) {}

  getTranscriptAnalysis(
    categories: ConversationCategoryDto[],
    calltranscripts: CallTranscriptDto[],
    extraFilters?: any
  ): CategoryMarkerDto[] {
    let filteredCategories = categories.filter(x => x.rootQueryId == null);

    if (extraFilters) {
      filteredCategories = filteredCategories.filter(extraFilters);
    }
    this.applyCombinedQueries(filteredCategories, categories);

    let conversationMarkResults: CategoryMarkerDto[] = [];
    this._callTranscripts = calltranscripts;
    this.setCallTranscriptIndexes(this._callTranscripts);
    this._groupedTranscript[1] = this._callTranscripts.filter(
      x => x.channel == this.callTranscriptChannel.Agent
    );
    this._groupedTranscript[2] = this._callTranscripts.filter(
      x => x.channel == this.callTranscriptChannel.Customer
    );
    filteredCategories.forEach(category => {
      if (category.searchTermItem != null) {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkSearchTerm(category.searchTermItem, category)
        );
      }

      category.commentTermItems?.forEach(comment => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkCommentTerm(comment, category)
        );
      });

      category.topicTermItems?.forEach(topic => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkTopicTerm(topic, category)
        );
      });

      category.simpleTermItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkSimpleTermQuery(queryItem, category)
        );
      });
      category.simpleTermWithRangeItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkSimpleTermWithRangeQuery(queryItem, category)
        );
      });
      category.simpleTermWithOccurenceItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkSimpleTermWithOccurrenceQuery(queryItem, category)
        );
      });
      category.simpleTermWithRangeAndOccurenceItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkSimpleTermWithRangeAndOccurrenceQuery(queryItem, category)
        );
      });
      category.nearItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkNearQuery(queryItem, category)
        );
      });
      category.orderedGroupWithRangeItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkOrderedGroupWithRangeQuery(queryItem, category)
        );
      });
      category.orderedGroupItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkOrderedGroupQuery(queryItem, category)
        );
      });
    });
    return conversationMarkResults;
  }

  getConversationTranscriptAnalysis(
    categories: ConversationCategoryDto[],
    transcript: ConversationTranscriptDto,
    extraFilters?: any
  ): CategoryMarkerDto[] {
    let filteredCategories = categories.filter(x => x.rootQueryId == null);

    if (extraFilters) {
      filteredCategories = filteredCategories.filter(extraFilters);
    }
    this.applyCombinedQueries(filteredCategories, categories);

    let conversationMarkResults: CategoryMarkerDto[] = [];
    this._transcript = transcript;
    if (this._transcript) {
      this.setConversationTranscriptIndexes(this._transcript.items);
      this._groupedNewTranscript[1] = this._transcript.items?.filter(
        x => x.channel == this.conversationTranscriptItemChannel.Agent
      );
      this._groupedNewTranscript[2] = this._transcript.items?.filter(
        x => x.channel == this.conversationTranscriptItemChannel.Customer
      );
    }
    filteredCategories.forEach(category => {
      if (category.searchTermItem != null) {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkSearchTermNew(category.searchTermItem, category)
        );
      }

      category.commentTermItems?.forEach(comment => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkCommentTerm(comment, category)
        );
      });

      category.topicTermItems?.forEach(topic => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkTopicTermNew(topic, category)
        );
      });

      category.simpleTermItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkSimpleTermQueryNew(queryItem, category)
        );
      });
      category.simpleTermWithRangeItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkSimpleTermWithRangeQueryNew(queryItem, category)
        );
      });
      category.simpleTermWithOccurenceItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkSimpleTermWithOccurrenceQueryNew(queryItem, category)
        );
      });
      category.simpleTermWithRangeAndOccurenceItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkSimpleTermWithRangeAndOccurrenceQueryNew(queryItem, category)
        );
      });
      category.nearItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkNearQueryNew(queryItem, category)
        );
      });
      category.nearWithRangeItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkNearQueryNew(queryItem, category) // Daha sonra NearWithRange olarak düzenlenecek.
        );
      });
      category.orderedGroupWithRangeItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkOrderedGroupWithRangeQueryNew(queryItem, category)
        );
      });
      category.orderedGroupItems?.forEach(queryItem => {
        conversationMarkResults = conversationMarkResults.concat(
          this.checkOrderedGroupQueryNew(queryItem, category)
        );
      });
    });
    return conversationMarkResults;
  }

  getTranscriptRows(
    conversationTypeId: number,
    transcript: ConversationTranscriptItemDto[]
  ): TranscriptRow[] {
    const result: TranscriptRow[] = [];
    const chatViewThreshold = parseInt(
      this.config.getSetting('Conversation.ChatViewThreshold'),
      10
    );

    transcript.sort((x, y) => {
      return x.startTime - y.startTime;
    });

    transcript.forEach(item => {
      const t = Object.assign(new ConversationTranscriptItemDto(), item);
      const lastRow = result[result.length - 1];
      const rowsOfChannel =
        t.channel === this.conversationTranscriptItemChannel.Participant
          ? result.filter(x => x.channel === t.participantName)
          : result.filter(x => x.channel === t.channel);
      const lastRowOfChannel =
        rowsOfChannel.length > 0 ? rowsOfChannel[rowsOfChannel.length - 1] : null;

      if (
        conversationTypeId !== ConversationType.chat &&
        lastRowOfChannel !== null &&
        lastRowOfChannel.endMillisecond + chatViewThreshold >= t.startTime
      ) {
        lastRowOfChannel.words.push({
          startTime: t.startTime,
          endTime: t.endTime,
          word: t.term,
        });
        lastRowOfChannel.endMillisecond = t.endTime;
      } else if (
        conversationTypeId !== ConversationType.chat &&
        lastRow &&
        (lastRow.channel === t.channel || lastRow.channel === t.participantName)
      ) {
        lastRowOfChannel.words.push({
          startTime: t.startTime,
          endTime: t.endTime,
          word: t.term,
        });
        lastRowOfChannel.endMillisecond = t.endTime;
      } else if (
        conversationTypeId == ConversationType.chat &&
        lastRowOfChannel !== null &&
        lastRowOfChannel.rowIdentifier === t.rowIdentifier
      ) {
        lastRowOfChannel.words.push({
          startTime: t.startTime,
          endTime: t.endTime,
          word: t.term,
        });
      } else {
        let time = '';

        if (t.time) {
          time = this.caDatePipe.transform(t.time, DateDisplayType.Time);
        } else if (t.startTime) {
          time = this.convertTimeToString(t.startTime);
        }

        const row: TranscriptRow = {
          channel:
            t.channel === this.conversationTranscriptItemChannel.Participant
              ? t.participantName
              : t.channel,
          time: time,
          words: [
            {
              endTime: t.endTime,
              startTime: t.startTime,
              word: t.term,
            },
          ],
          endMillisecond: t.endTime,
          startMillisecond: t.startTime,
          rowIdentifier: t.rowIdentifier,
          participantName: t.participantName,
        };

        result.push(row);
      }
    });

    return result;
  }

  private checkWordEquality(transcriptWord: string, termWord: string, operator: number) {
    if (
      operator === this.operators.Equals &&
      this.stringService.toLowerForCurrentCulture(transcriptWord) ===
        this.stringService.toLowerForCurrentCulture(termWord)
    ) {
      return true;
    } else if (
      operator === this.operators.Contains &&
      this.stringService
        .toLowerForCurrentCulture(transcriptWord)
        .indexOf(this.stringService.toLowerForCurrentCulture(termWord)) >= 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  private convertTimeToString(currentTime: number) {
    const seconds = Math.floor(currentTime / 1000);
    return this.caTimePipe.transform(seconds);
  }

  private getOccurrences(
    term: string,
    operator: number,
    index: number = 0,
    sideId: number
  ): CallOccurrenceDto[] {
    let occurrences: CallOccurrenceDto[] = [];
    let termArray = term.trim().split(' ');
    let channel = this.callTranscriptChannel.Agent;
    this._groupedTranscript.forEach(transcript => {
      for (let i = 0; i < transcript.length; i++) {
        if (termArray.length > 0 && transcript[i].index >= index) {
          let words: TranscriptWord[] = [];
          for (let k = 0; k < termArray.length; k++) {
            if (
              transcript[i + k] != null &&
              this.checkWordEquality(transcript[i + k].word, termArray[k], operator)
            ) {
              words.push({
                word: transcript[i + k].word,
                startTime: transcript[i + k].startTime,
                endTime: transcript[i + k].endTime,
              });
              if (k === termArray.length - 1) {
                if (this.checkSide(sideId, transcript[i].channel)) {
                  occurrences.push({
                    startMillisecond: transcript[i].startTime,
                    channel: channel,
                    index: transcript[i].index,
                    words: words,
                  });
                }
              }
            } else {
              break;
            }
          }
        }
      }
      channel++;
    });

    return occurrences;
  }

  private getOccurrencesNew(
    term: string,
    operator: number,
    index: number = 0,
    sideId: number
  ): CallOccurrenceDto[] {
    let occurrences: CallOccurrenceDto[] = [];
    let termArray = term.trim().split(' ');
    let channel = this.callTranscriptChannel.Agent;
    this._groupedNewTranscript.forEach(transcript => {
      for (let i = 0; i < transcript.length; i++) {
        if (termArray.length > 0 && transcript[i].index >= index) {
          let words: TranscriptWord[] = [];
          for (let k = 0; k < termArray.length; k++) {
            if (
              transcript[i + k] != null &&
              this.checkWordEquality(transcript[i + k].term, termArray[k], operator)
            ) {
              words.push({
                word: transcript[i + k].term,
                startTime: transcript[i + k].startTime,
                endTime: transcript[i + k].endTime,
              });
              if (k === termArray.length - 1) {
                if (this.checkSide(sideId, transcript[i].channel)) {
                  occurrences.push({
                    startMillisecond: transcript[i].startTime,
                    channel: channel,
                    index: transcript[i].index,
                    agentIndex: transcript[i].agentIndex,
                    customerIndex: transcript[i].customerIndex,
                    words: words,
                  });
                }
              }
            } else {
              break;
            }
          }
        }
      }
      channel++;
    });

    return occurrences;
  }

  private checkNearQuery(query: NearQueryItemDto, category: ConversationCategoryDto) {
    let marks: CategoryMarkerDto[] = [];

    let firstTermOccurrences = this.getOccurrences(
      query.firstTerm,
      query.firstOperator,
      0,
      query.sideId
    );

    let secondTermOccurences = this.getOccurrences(
      query.secondTerm,
      query.secondOperator,
      0,
      query.sideId
    );
    if (firstTermOccurrences.length > 0 && secondTermOccurences.length > 0) {
      firstTermOccurrences.forEach(occurrence => {
        let secondTermOccurrencesInRange = secondTermOccurences.filter(
          x => x.index > occurrence.index && x.index <= occurrence.index + query.maximumDistance + 1
        );
        if (secondTermOccurrencesInRange.length > 0) {
          let mark = new CategoryMarkerDto();
          mark.color = category.categoryColor;
          mark.text = occurrence.words
            .map(item => {
              return item.word;
            })
            .join(' ');
          mark.channel = occurrence.channel;
          mark.startMillisecond = occurrence.startMillisecond;
          mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
          mark.categoryName = category.categoryName;
          mark.words = occurrence.words;
          mark.isQuickSearchMarker = category.isQuickSearchMarker;
          mark.isFilterSearchMarker = category.isFilterSearchMarker;
          marks.push(mark);
          secondTermOccurrencesInRange.forEach(secondTermOccurrence => {
            {
              let mark = new CategoryMarkerDto();
              mark.color = category.categoryColor;
              mark.text = secondTermOccurrence.words
                .map(item => {
                  return item.word;
                })
                .join(' ');
              mark.channel = secondTermOccurrence.channel;
              mark.startMillisecond = secondTermOccurrence.startMillisecond;
              mark.endMillisecond =
                secondTermOccurrence.words[secondTermOccurrence.words.length - 1].endTime;
              mark.categoryName = category.categoryName;
              mark.words = secondTermOccurrence.words;
              mark.isQuickSearchMarker = category.isQuickSearchMarker;
              mark.isFilterSearchMarker = category.isFilterSearchMarker;
              marks.push(mark);
            }
          });
        }
      });

      if (!query.searchInOrder) {
        secondTermOccurences.forEach(occurrence => {
          let firstTermOcurrencesInRange = firstTermOccurrences.filter(
            x =>
              x.index > occurrence.index && x.index <= occurrence.index + query.maximumDistance + 1
          );
          if (firstTermOcurrencesInRange.length > 0) {
            let mark = new CategoryMarkerDto();
            mark.color = category.categoryColor;
            mark.text = occurrence.words
              .map(item => {
                return item.word;
              })
              .join(' ');
            mark.channel = occurrence.channel;
            mark.startMillisecond = occurrence.startMillisecond;
            mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
            mark.categoryName = category.categoryName;
            mark.words = occurrence.words;
            mark.isQuickSearchMarker = category.isQuickSearchMarker;
            mark.isFilterSearchMarker = category.isFilterSearchMarker;
            marks.push(mark);
            firstTermOcurrencesInRange.forEach(firstTermOccurrence => {
              {
                let mark = new CategoryMarkerDto();
                mark.color = category.categoryColor;
                mark.text = firstTermOccurrence.words
                  .map(item => {
                    return item.word;
                  })
                  .join(' ');
                mark.channel = firstTermOccurrence.channel;
                mark.startMillisecond = firstTermOccurrence.startMillisecond;
                mark.endMillisecond =
                  firstTermOccurrence.words[firstTermOccurrence.words.length - 1].endTime;
                mark.categoryName = category.categoryName;
                mark.words = firstTermOccurrence.words;
                mark.isQuickSearchMarker = category.isQuickSearchMarker;
                mark.isFilterSearchMarker = category.isFilterSearchMarker;
                marks.push(mark);
              }
            });
          }
        });
      }
    }

    return marks;
  }

  private checkNearQueryNew(query: NearQueryItemDto, category: ConversationCategoryDto) {
    let marks: CategoryMarkerDto[] = [];

    let firstTermOccurrences = this.getOccurrencesNew(
      query.firstTerm,
      query.firstOperator,
      0,
      query.sideId
    );

    let secondTermOccurences = this.getOccurrencesNew(
      query.secondTerm,
      query.secondOperator,
      0,
      query.sideId
    );
    if (firstTermOccurrences.length > 0 && secondTermOccurences.length > 0) {
      firstTermOccurrences.forEach(occurrence => {
        let secondTermOccurrencesInRange = secondTermOccurences.filter(
          x =>
            ((query.sideId === ConversationSide.any || query.sideId === ConversationSide.agent) &&
              x.agentIndex > occurrence.agentIndex &&
              x.agentIndex <= occurrence.agentIndex + query.maximumDistance + 1) ||
            ((query.sideId === ConversationSide.any ||
              query.sideId === ConversationSide.customer) &&
              x.customerIndex > occurrence.customerIndex &&
              x.customerIndex <= occurrence.customerIndex + query.maximumDistance + 1)
        );
        if (secondTermOccurrencesInRange.length > 0) {
          let mark = new CategoryMarkerDto();
          mark.color = category.categoryColor;
          mark.text = occurrence.words
            .map(item => {
              return item.word;
            })
            .join(' ');
          mark.channel = occurrence.channel;
          mark.startMillisecond = occurrence.startMillisecond;
          mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
          mark.categoryName = category.categoryName;
          mark.words = occurrence.words;
          mark.isQuickSearchMarker = category.isQuickSearchMarker;
          mark.isFilterSearchMarker = category.isFilterSearchMarker;
          marks.push(mark);
          secondTermOccurrencesInRange.forEach(secondTermOccurrence => {
            {
              let mark = new CategoryMarkerDto();
              mark.color = category.categoryColor;
              mark.text = secondTermOccurrence.words
                .map(item => {
                  return item.word;
                })
                .join(' ');
              mark.channel = secondTermOccurrence.channel;
              mark.startMillisecond = secondTermOccurrence.startMillisecond;
              mark.endMillisecond =
                secondTermOccurrence.words[secondTermOccurrence.words.length - 1].endTime;
              mark.categoryName = category.categoryName;
              mark.words = secondTermOccurrence.words;
              mark.isQuickSearchMarker = category.isQuickSearchMarker;
              mark.isFilterSearchMarker = category.isFilterSearchMarker;
              marks.push(mark);
            }
          });
        }
      });

      if (!query.searchInOrder) {
        secondTermOccurences.forEach(occurrence => {
          let firstTermOcurrencesInRange = firstTermOccurrences.filter(
            x =>
              ((query.sideId === ConversationSide.any || query.sideId === ConversationSide.agent) &&
                x.agentIndex > occurrence.agentIndex &&
                x.agentIndex <= occurrence.agentIndex + query.maximumDistance + 1) ||
              ((query.sideId === ConversationSide.any ||
                query.sideId === ConversationSide.customer) &&
                x.customerIndex > occurrence.customerIndex &&
                x.customerIndex <= occurrence.customerIndex + query.maximumDistance + 1)
          );
          if (firstTermOcurrencesInRange.length > 0) {
            let mark = new CategoryMarkerDto();
            mark.color = category.categoryColor;
            mark.text = occurrence.words
              .map(item => {
                return item.word;
              })
              .join(' ');
            mark.channel = occurrence.channel;
            mark.startMillisecond = occurrence.startMillisecond;
            mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
            mark.categoryName = category.categoryName;
            mark.words = occurrence.words;
            mark.isQuickSearchMarker = category.isQuickSearchMarker;
            mark.isFilterSearchMarker = category.isFilterSearchMarker;
            marks.push(mark);
            firstTermOcurrencesInRange.forEach(firstTermOccurrence => {
              {
                let mark = new CategoryMarkerDto();
                mark.color = category.categoryColor;
                mark.text = firstTermOccurrence.words
                  .map(item => {
                    return item.word;
                  })
                  .join(' ');
                mark.channel = firstTermOccurrence.channel;
                mark.startMillisecond = firstTermOccurrence.startMillisecond;
                mark.endMillisecond =
                  firstTermOccurrence.words[firstTermOccurrence.words.length - 1].endTime;
                mark.categoryName = category.categoryName;
                mark.words = firstTermOccurrence.words;
                mark.isQuickSearchMarker = category.isQuickSearchMarker;
                mark.isFilterSearchMarker = category.isFilterSearchMarker;
                marks.push(mark);
              }
            });
          }
        });
      }
    }

    return marks;
  }

  private checkOrderedGroupQuery(
    query: OrderedGroupQueryItemDto,
    category: ConversationCategoryDto
  ) {
    let marks = this.getOrderedSimpleCategoryMarkers(query.internalId, category, null, null);
    return marks;
  }

  private checkOrderedGroupQueryNew(
    query: OrderedGroupQueryItemDto,
    category: ConversationCategoryDto
  ) {
    let marks = this.getOrderedSimpleCategoryMarkersNew(query.internalId, category, null, null);
    return marks;
  }

  private checkOrderedGroupWithRangeQuery(
    query: OrderedGroupWithRangeQueryItemDto,
    category: ConversationCategoryDto
  ) {
    let marks = this.getOrderedSimpleCategoryMarkers(
      query.internalId,
      category,
      query.startTime,
      query.endTime
    );
    return marks;
  }

  private checkOrderedGroupWithRangeQueryNew(
    query: OrderedGroupWithRangeQueryItemDto,
    category: ConversationCategoryDto
  ) {
    let marks = this.getOrderedSimpleCategoryMarkersNew(
      query.internalId,
      category,
      query.startTime,
      query.endTime
    );
    return marks;
  }

  private checkSimpleTermQuery(
    query: SimpleTermQueryItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];

    let occurrences = this.getOccurrences(query.term, query.operator, 0, query.sideId);
    occurrences.forEach(occurrence => {
      if (occurrences.length > 0) {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = occurrence.channel;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      }
    });

    return marks;
  }

  private checkSimpleTermQueryNew(
    query: SimpleTermQueryItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];

    let occurrences = this.getOccurrencesNew(query.term, query.operator, 0, query.sideId);
    occurrences.forEach(occurrence => {
      if (occurrences.length > 0) {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = occurrence.channel;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      }
    });

    return marks;
  }

  private checkCommentTerm(
    comment: CommentTermItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];

    let mark = new CategoryMarkerDto();
    mark.color = category.categoryColor;
    mark.text = comment.term;
    mark.channel = this.callTranscriptChannel.Agent;
    mark.startMillisecond = this.formatHelper.convertStringToTimerSecond(comment.startTime) * 1000;
    mark.endMillisecond = this.formatHelper.convertStringToTimerSecond(comment.endTime) * 1000;
    mark.categoryName = category.categoryName;
    mark.isCommentMarker = true;
    marks.push(mark);

    return marks;
  }

  private checkTopicTerm(
    topic: AIGeneratedTermItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];

    let occurrences = this.getOccurrences(
      topic.term,
      this.operators.Equals,
      0,
      ConversationSide.any
    );
    occurrences.forEach(occurrence => {
      if (occurrences.length > 0) {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = occurrence.channel;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        mark.isAIGeneratedMarker = true;
        marks.push(mark);
      }
    });

    return marks;
  }

  private checkTopicTermNew(
    topic: AIGeneratedTermItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];

    let occurrences = this.getOccurrencesNew(
      topic.term,
      this.operators.Equals,
      0,
      ConversationSide.any
    );
    occurrences.forEach(occurrence => {
      if (occurrences.length > 0) {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = occurrence.channel;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        mark.isAIGeneratedMarker = true;
        marks.push(mark);
      }
    });

    return marks;
  }

  private checkSearchTerm(
    searchTerm: SearchTermItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];
    let operator = this.operators.Equals;

    if (searchTerm.term.indexOf('*') > -1) {
      operator = this.operators.Contains;
    }

    let occurrences = this.getOccurrences(
      searchTerm.term.replace('*', ''),
      operator,
      0,
      ConversationSide.any
    );
    occurrences.forEach(occurrence => {
      if (occurrences.length > 0) {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = occurrence.channel;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      }
    });
    return marks;
  }

  private checkSearchTermNew(
    searchTerm: SearchTermItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];
    let operator = this.operators.Equals;

    if (searchTerm.term.indexOf('*') > -1) {
      operator = this.operators.Contains;
    }

    let occurrences = this.getOccurrencesNew(
      searchTerm.term.replace('*', ''),
      operator,
      0,
      ConversationSide.any
    );
    occurrences.forEach(occurrence => {
      if (occurrences.length > 0) {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = occurrence.channel;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      }
    });
    return marks;
  }

  private checkSimpleTermWithRangeQuery(
    query: SimpleTermWithRangeQueryItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];
    let occurrences = this.getOccurrences(query.term, query.operator, 0, query.sideId);
    occurrences.forEach(occurrence => {
      if (
        this.checkRange(
          query.startTime,
          query.endTime,
          query.rangeUnitId,
          occurrence.startMillisecond
        )
      ) {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = occurrence.channel;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      }
    });
    return marks;
  }

  private checkSimpleTermWithRangeQueryNew(
    query: SimpleTermWithRangeQueryItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];
    let occurrences = this.getOccurrencesNew(query.term, query.operator, 0, query.sideId);
    occurrences.forEach(occurrence => {
      if (
        this.checkRangeNew(
          query.startTime,
          query.endTime,
          query.rangeUnitId,
          occurrence.startMillisecond
        )
      ) {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = occurrence.channel;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      }
    });
    return marks;
  }

  private checkSimpleTermWithOccurrenceQuery(
    query: SimpleTermWithOccurenceQueryItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];
    let occurrences = this.getOccurrences(query.term, query.operator, 0, query.sideId);
    if (query.sideId === ConversationSide.any || query.sideId === ConversationSide.customer) {
      let agentoccurrences = occurrences.filter(
        x => x.channel === this.callTranscriptChannel.Customer
      );
      agentoccurrences.forEach(occurrence => {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = this.callTranscriptChannel.Customer;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      });
    }
    if (query.sideId === ConversationSide.any || query.sideId === ConversationSide.agent) {
      let agentoccurrences = occurrences.filter(
        x => x.channel === this.callTranscriptChannel.Agent
      );
      agentoccurrences.forEach(occurrence => {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = this.callTranscriptChannel.Agent;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      });
    }

    return marks;
  }

  private checkSimpleTermWithOccurrenceQueryNew(
    query: SimpleTermWithOccurenceQueryItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];
    let occurrences = this.getOccurrencesNew(query.term, query.operator, 0, query.sideId);
    if (query.sideId === ConversationSide.any || query.sideId === ConversationSide.customer) {
      let agentoccurrences = occurrences.filter(
        x => x.channel === this.callTranscriptChannel.Customer
      );
      agentoccurrences.forEach(occurrence => {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = this.callTranscriptChannel.Customer;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      });
    }
    if (query.sideId === ConversationSide.any || query.sideId === ConversationSide.agent) {
      let agentoccurrences = occurrences.filter(
        x => x.channel === this.callTranscriptChannel.Agent
      );
      agentoccurrences.forEach(occurrence => {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = this.callTranscriptChannel.Agent;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      });
    }

    return marks;
  }

  private checkSimpleTermWithRangeAndOccurrenceQuery(
    query: SimpleTermWithRangeAndOccurenceQueryItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];
    let occurrences = this.getOccurrences(query.term, query.operator, 0, ConversationSide.any);
    if (query.sideId === ConversationSide.any || query.sideId === ConversationSide.customer) {
      let agentoccurrences = occurrences.filter(
        x =>
          x.channel === this.callTranscriptChannel.Customer &&
          x.startMillisecond >= this.getQueryStartMilliSecond(query) &&
          x.startMillisecond <= this.getQueryEndMilliSecond(query)
      );
      agentoccurrences.forEach(occurrence => {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = this.callTranscriptChannel.Customer;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      });
    }
    if (query.sideId === ConversationSide.any || query.sideId === ConversationSide.agent) {
      let agentoccurrences = occurrences.filter(
        x => x.channel === this.callTranscriptChannel.Agent
      );
      agentoccurrences.forEach(occurrence => {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = this.callTranscriptChannel.Agent;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      });
    }

    return marks;
  }

  private checkSimpleTermWithRangeAndOccurrenceQueryNew(
    query: SimpleTermWithRangeAndOccurenceQueryItemDto,
    category: ConversationCategoryDto
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];
    let occurrences = this.getOccurrencesNew(query.term, query.operator, 0, ConversationSide.any);
    if (query.sideId === ConversationSide.any || query.sideId === ConversationSide.customer) {
      let agentoccurrences = occurrences.filter(
        x =>
          x.channel === this.callTranscriptChannel.Customer &&
          x.startMillisecond >= this.getQueryStartMilliSecond(query) &&
          x.startMillisecond <= this.getQueryEndMilliSecond(query)
      );
      agentoccurrences.forEach(occurrence => {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = this.callTranscriptChannel.Customer;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      });
    }
    if (query.sideId === ConversationSide.any || query.sideId === ConversationSide.agent) {
      let agentoccurrences = occurrences.filter(
        x => x.channel === this.callTranscriptChannel.Agent
      );
      agentoccurrences.forEach(occurrence => {
        let mark = new CategoryMarkerDto();
        mark.color = category.categoryColor;
        mark.text = occurrence.words
          .map(item => {
            return item.word;
          })
          .join(' ');
        mark.channel = this.callTranscriptChannel.Agent;
        mark.startMillisecond = occurrence.startMillisecond;
        mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
        mark.categoryName = category.categoryName;
        mark.words = occurrence.words;
        mark.isQuickSearchMarker = category.isQuickSearchMarker;
        mark.isFilterSearchMarker = category.isFilterSearchMarker;
        marks.push(mark);
      });
    }

    return marks;
  }

  private checkRange(
    queryStart: number,
    queryEnd: number,
    rangeUnitId: number,
    occurrenceStartMillisecond: number
  ): boolean {
    let result: boolean = false;
    if (rangeUnitId === QueryRangeUnit.percentage) {
      const transcriptEndTimeMillisecond =
        this._callTranscripts[this._callTranscripts.length - 1].startTime;
      const queryStartTimeMillisecond = (transcriptEndTimeMillisecond * queryStart) / 100;
      const queryEndTimeMillisecond = (transcriptEndTimeMillisecond * queryEnd) / 100;
      if (
        occurrenceStartMillisecond >= queryStartTimeMillisecond &&
        occurrenceStartMillisecond <= queryEndTimeMillisecond
      ) {
        result = true;
      }
    } else {
      if (queryStart < queryEnd) {
        if (
          occurrenceStartMillisecond >= queryStart * 1000 &&
          occurrenceStartMillisecond <= queryEnd * 1000
        ) {
          result = true;
        }
      } else {
        const transcriptEndTimeMillisecond =
          this._callTranscripts[this._callTranscripts.length - 1].startTime - queryStart * 1000;
        if (occurrenceStartMillisecond >= transcriptEndTimeMillisecond) {
          result = true;
        }
      }
    }
    return result;
  }

  private checkRangeNew(
    queryStart: number,
    queryEnd: number,
    rangeUnitId: number,
    occurrenceStartMillisecond: number
  ): boolean {
    let result: boolean = false;
    if (rangeUnitId === QueryRangeUnit.percentage) {
      const transcriptEndTimeMillisecond =
        this._transcript.items[this._transcript.items.length - 1].startTime;
      const queryStartTimeMillisecond = (transcriptEndTimeMillisecond * queryStart) / 100;
      const queryEndTimeMillisecond = (transcriptEndTimeMillisecond * queryEnd) / 100;
      if (
        occurrenceStartMillisecond >= queryStartTimeMillisecond &&
        occurrenceStartMillisecond <= queryEndTimeMillisecond
      ) {
        result = true;
      }
    } else {
      if (queryStart < queryEnd) {
        if (
          occurrenceStartMillisecond >= queryStart * 1000 &&
          occurrenceStartMillisecond <= queryEnd * 1000
        ) {
          result = true;
        }
      } else {
        const transcriptEndTimeMillisecond =
          this._transcript.items[this._transcript.items.length - 1].startTime - queryStart * 1000;
        if (occurrenceStartMillisecond >= transcriptEndTimeMillisecond) {
          result = true;
        }
      }
    }
    return result;
  }

  private checkSide(sideId: number, channelId: number): boolean {
    if (
      ((sideId === ConversationSide.any || sideId === ConversationSide.customer) &&
        channelId == this.callTranscriptChannel.Customer) ||
      ((sideId === ConversationSide.any || sideId === ConversationSide.agent) &&
        channelId == this.callTranscriptChannel.Agent)
    ) {
      return true;
    } else {
      return false;
    }
  }

  private getQueryStartMilliSecond(query: any): number {
    const transcriptEndTimeMillisecond =
      this._callTranscripts[this._callTranscripts.length - 1].startTime;
    if (query.rangeUnitId === QueryRangeUnit.percentage) {
      return (transcriptEndTimeMillisecond * query.endTime) / 100;
    } else {
      return query.startTime * 1000;
    }
  }

  private getQueryEndMilliSecond(query: any): number {
    const transcriptEndTimeMillisecond =
      this._callTranscripts[this._callTranscripts.length - 1].startTime;
    if (query.rangeUnitId === QueryRangeUnit.percentage) {
      return (transcriptEndTimeMillisecond * query.endTime) / 100;
    } else {
      return query.endTime * 1000;
    }
  }

  private getOrderedSimpleCategoryMarkers(
    internalId: number,
    category: ConversationCategoryDto,
    startSecond: number,
    endSecond: number
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];
    let items: OrderedSimpleTermQueryItemDto[] = category.orderedSimpleTermItems
      .filter(x => x.parentInternalId === internalId)
      .sort((a, b) => a.internalId);

    if (items.length > 0) {
      // get first terms occurrences
      let firstItemOcurrences = this.getOccurrences(
        items[0].term,
        items[0].operator,
        0,
        items[0].sideId
      );

      firstItemOcurrences = this.applyOrderedRangeFilter(
        firstItemOcurrences,
        startSecond,
        endSecond
      );

      firstItemOcurrences.forEach(occurrence => {
        let tempMarks: CategoryMarkerDto[] = [];

        if (
          !this.checkQueryMarkerExist(
            marks,
            items[0].term,
            occurrence.channel,
            occurrence.startMillisecond
          )
        ) {
          let mark = new CategoryMarkerDto();
          mark.color = category.categoryColor;
          mark.text = occurrence.words
            .map(item => {
              return item.word;
            })
            .join(' ');
          mark.channel = occurrence.channel;
          mark.startMillisecond = occurrence.startMillisecond;
          mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
          mark.categoryName = category.categoryName;
          mark.words = occurrence.words;
          mark.isQuickSearchMarker = category.isQuickSearchMarker;
          mark.isFilterSearchMarker = category.isFilterSearchMarker;
          tempMarks.push(mark);
        }

        let lastIndex = occurrence.index + items[0].term.trim().split(' ').length;

        // check next terms occurrences
        for (let i = 1; i < items.length; i++) {
          let nextTermOccurence = this.getOccurrences(
            items[i].term,
            items[i].operator,
            lastIndex,
            items[i].sideId
          );
          firstItemOcurrences = this.applyOrderedRangeFilter(
            nextTermOccurence,
            startSecond,
            endSecond
          );

          if (nextTermOccurence.length > 0) {
            if (
              !this.checkQueryMarkerExist(
                marks,
                items[i].term,
                nextTermOccurence[0].channel,
                nextTermOccurence[0].startMillisecond
              )
            ) {
              let mark = new CategoryMarkerDto();
              mark.color = category.categoryColor;
              mark.text = this._callTranscripts[nextTermOccurence[0].index].word;
              mark.channel = nextTermOccurence[0].channel;
              mark.startMillisecond = nextTermOccurence[0].startMillisecond;
              mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
              mark.categoryName = category.categoryName;
              mark.words = nextTermOccurence[0].words;
              mark.isQuickSearchMarker = category.isQuickSearchMarker;
              mark.isFilterSearchMarker = category.isFilterSearchMarker;
              tempMarks.push(mark);
            }
            if (i + 1 == items.length) {
              marks = marks.concat(tempMarks);
            }
            lastIndex = nextTermOccurence[0].index + items[i].term.trim().split(' ').length;
          } else {
            break;
          }
        }
      });
    }
    return marks;
  }

  private getOrderedSimpleCategoryMarkersNew(
    internalId: number,
    category: ConversationCategoryDto,
    startSecond: number,
    endSecond: number
  ): CategoryMarkerDto[] {
    let marks: CategoryMarkerDto[] = [];
    let items: OrderedSimpleTermQueryItemDto[] = category.orderedSimpleTermItems
      .filter(x => x.parentInternalId === internalId)
      .sort((a, b) => a.internalId);

    if (items.length > 0) {
      // get first terms occurrences
      let firstItemOcurrences = this.getOccurrencesNew(
        items[0].term,
        items[0].operator,
        0,
        items[0].sideId
      );

      firstItemOcurrences = this.applyOrderedRangeFilterNew(
        firstItemOcurrences,
        startSecond,
        endSecond
      );

      firstItemOcurrences.forEach(occurrence => {
        let tempMarks: CategoryMarkerDto[] = [];

        if (
          !this.checkQueryMarkerExist(
            marks,
            items[0].term,
            occurrence.channel,
            occurrence.startMillisecond
          )
        ) {
          let mark = new CategoryMarkerDto();
          mark.color = category.categoryColor;
          mark.text = occurrence.words
            .map(item => {
              return item.word;
            })
            .join(' ');
          mark.channel = occurrence.channel;
          mark.startMillisecond = occurrence.startMillisecond;
          mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
          mark.categoryName = category.categoryName;
          mark.words = occurrence.words;
          mark.isQuickSearchMarker = category.isQuickSearchMarker;
          mark.isFilterSearchMarker = category.isFilterSearchMarker;
          tempMarks.push(mark);
        }

        let lastIndex = occurrence.index + items[0].term.trim().split(' ').length;

        // check next terms occurrences
        for (let i = 1; i < items.length; i++) {
          let nextTermOccurence = this.getOccurrencesNew(
            items[i].term,
            items[i].operator,
            lastIndex,
            items[i].sideId
          );
          firstItemOcurrences = this.applyOrderedRangeFilterNew(
            nextTermOccurence,
            startSecond,
            endSecond
          );

          if (nextTermOccurence.length > 0) {
            if (
              !this.checkQueryMarkerExist(
                marks,
                items[i].term,
                nextTermOccurence[0].channel,
                nextTermOccurence[0].startMillisecond
              )
            ) {
              let mark = new CategoryMarkerDto();
              mark.color = category.categoryColor;
              mark.text = this._transcript.items[nextTermOccurence[0].index].term;
              mark.channel = nextTermOccurence[0].channel;
              mark.startMillisecond = nextTermOccurence[0].startMillisecond;
              mark.endMillisecond = occurrence.words[occurrence.words.length - 1].endTime;
              mark.categoryName = category.categoryName;
              mark.words = nextTermOccurence[0].words;
              mark.isQuickSearchMarker = category.isQuickSearchMarker;
              mark.isFilterSearchMarker = category.isFilterSearchMarker;
              tempMarks.push(mark);
            }
            if (i + 1 == items.length) {
              marks = marks.concat(tempMarks);
            }
            lastIndex = nextTermOccurence[0].index + items[i].term.trim().split(' ').length;
          } else {
            break;
          }
        }
      });
    }
    return marks;
  }

  private setCallTranscriptIndexes(callTranscripts: CallTranscriptDto[]) {
    for (let i = 0; i < callTranscripts.length; i++) {
      callTranscripts[i].index = i;
    }
  }

  private setConversationTranscriptIndexes(transcript: ConversationTranscriptItemDto[]) {
    let agentIndex = 0;
    let customerIndex = 0;
    for (let i = 0; i < transcript.length; i++) {
      transcript[i].index = i;
      if (transcript[i].channel == this.conversationTranscriptItemChannel.Agent) {
        transcript[i].agentIndex = agentIndex++;
      }
      if (transcript[i].channel == this.conversationTranscriptItemChannel.Customer) {
        transcript[i].customerIndex = customerIndex++;
      }
    }
  }

  private checkQueryMarkerExist(
    marks: CategoryMarkerDto[],
    term: string,
    channel: number,
    startMillisecond
  ) {
    let count = marks.filter(
      x => x.text === term && x.channel === channel && x.startMillisecond === startMillisecond
    ).length;
    if (count === 0) {
      return false;
    } else {
      return true;
    }
  }

  private applyOrderedRangeFilter(
    occurrences: CallOccurrenceDto[],
    startSecond: number,
    endSecond: number
  ): CallOccurrenceDto[] {
    if (startSecond !== null && endSecond !== null && startSecond > endSecond) {
      let endTime = this._callTranscripts[this._callTranscripts.length - 1].endTime;
      return occurrences.filter(x => x.startMillisecond >= endTime - startSecond * 1000);
    } else if (startSecond != null && endSecond != null) {
      return occurrences.filter(
        x => x.startMillisecond >= startSecond * 1000 && x.startMillisecond <= endSecond * 1000
      );
    } else {
      return occurrences;
    }
  }

  private applyOrderedRangeFilterNew(
    occurrences: CallOccurrenceDto[],
    startSecond: number,
    endSecond: number
  ): CallOccurrenceDto[] {
    if (startSecond !== null && endSecond !== null && startSecond > endSecond) {
      let endTime = this._transcript.items[this._transcript.items.length - 1].endTime;
      return occurrences.filter(x => x.startMillisecond >= endTime - startSecond * 1000);
    } else if (startSecond != null && endSecond != null) {
      return occurrences.filter(
        x => x.startMillisecond >= startSecond * 1000 && x.startMillisecond <= endSecond * 1000
      );
    } else {
      return occurrences;
    }
  }

  private applyCombinedQueries(filteredCategories: any, categories) {
    filteredCategories.forEach(filteredCategory => {
      var combinedQueries = categories.filter(x => x.rootQueryId == filteredCategory.id);
      combinedQueries.forEach(combinedQuery => {
        combinedQuery.categoryName = filteredCategory.categoryName;
        combinedQuery.categoryColor = filteredCategory.categoryColor;
        filteredCategories.push(combinedQuery);
      });
    });
  }
}
